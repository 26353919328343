import menu from './menu';
import metadata from "./metadata";
import JurisdictionStaffDetail from "./components/JurisdictionStaff/Detail";
import JurisdictionTerminalList from "./components/JurisdictionCustomer/Detail";
import StaffAttachedStation from "./components/JurisdictionStaff/AttachedStation";
import CustomerAttachedStation from "./components/JurisdictionCustomer/AttachedStation";

const decorators = {
    structure: {
        menu: {
            add: menu.add
        }
    },
    module: {
        "jurisdiction-staff": {
            sceens: [
                'jurisdiction-staff-filter',
                'jurisdiction-staff-edit',
                'jurisdiction-staff-add',
                'jurisdiction-staff-list',
                "jurisdiction-staff-station-list",
                "jurisdiction-staff-station-add"
            ],
            "jurisdiction-staff-list": {
                load: '/business/affairs/jurisdiction/load',
                search: '/business/affairs/jurisdiction/search',
            },
            "jurisdiction-staff-station-list": {
                detailComponent: StaffAttachedStation,
                load: '/jurisdiction/post/load'
            },
            detailComponent: JurisdictionStaffDetail
        },
        "jurisdiction-customer": {
            sceens: [
                'jurisdiction-customer-filter',
                'jurisdiction-customer-list',
                'jurisdiction-customer-add',
                'jurisdiction-customer-edit',
                'jurisdiction-customer-jurisdiction-add',
                'jurisdiction-customer-jurisdiction-edit',
                'jurisdiction-customer-station-list',
                'jurisdiction-customer-station-add',
            ],
            "jurisdiction-customer-list": {
                load: '/sales/jurisdiction/load',
                search: '/sales/jurisdiction/search'
            },
            "jurisdiction-customer-station-list": {
                detailComponent: CustomerAttachedStation,
                load: '/jurisdiction/post/load'
            },
            detailComponent: JurisdictionTerminalList
        },
        "jurisdiction-terminal": {
            "sceens": [
                'jurisdiction-terminal-filter',
                'jurisdiction-terminal-list',
                'jurisdiction-terminal-add',
            ],
            "jurisdiction-terminal-list": {
                load: {
                    url: '/sales/jurisdiction/terminal/list',
                    method: 'POST'
                },
                search: '/sales/jurisdiction/terminal/list'
            }
        }
    },
    ui: {
        metadata: {
            moreMetadataComponents: metadata
        }
    }
};

export default { decorators }
