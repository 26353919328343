import React, { Component } from 'react'
import { stub } from '@xsk/frontend-skeleton'
import { Button, Form, Select, Modal, Typography, Tag  } from 'antd'
import _ from 'lodash'

const AddModal = Form.create()(props => {
    const { form, visible, onSubmit, onCancel, options, onSearch, loading } = props;
    const { getFieldDecorator, validateFields } = form;
    const handleSubmit = () => {
        validateFields((err, values) => {
            if (!err){
                onSubmit(values)
            }
        })
    }
    return(
        <Modal
            title="挂靠医院"
            visible={visible}
            onOk={handleSubmit}
            onCancel={onCancel}
            confirmLoading={loading}
        >
            <Form layout="inline">
                <Form.Item label="医疗卫生机构" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} >
                    {getFieldDecorator('healthCareId',{
                        initialValue: null,
                        rules: [{ required: true, message: '请选择医疗卫生机构'}]
                    })(
                        <Select
                            showSearch
                            onSearch={onSearch}
                            showArrow={false}
                            placeholder="请输入机构名称搜索"
                            filterOption={false}
                            style={{width: "100%"}}
                        >
                            {options.map(t => <Select.Option key={t.value}>{t.label}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>
            </Form>
        </Modal>
    )
});

class DependOnHealthCare extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: null,
            options: [],
            visible: false,
            loading: false
        }
        this.handleSearch = _.debounce(this.handleSearch, 500)
    }

    componentDidMount() {
        stub.api.get('/institutionRelation/detail', {
            params: { pharmacyId: this.props.page.params.id }
        }).then(res => {
            this.setState({
                data: res.data.result
            })
        })
    }

    handleSearch = (value) => {
        if (value) {
            stub.api.get('/institutionRelation/simpleHealthCareSearch', {
                params: { healthCareName: value }
            }).then(res => {
                this.setState({
                    options: res.data.result
                })
            })
        }
    };

    handleSubmit = (values) => {
        const dataToSubmit = {
            ...values,
            pharmacyId: this.props.page.params.id
        }
        this.setState({
            loading: true
        })
        stub.api.post('/institutionRelation/bind', dataToSubmit).then(res => {
            this.setState({
                data: res.data.result,
                visible: false,
                loading: false
            })
        })
    };

    handleCancel = (e) => {
        e&&e.stopPropagation()
        this.setState({
            visible: false
        })
    };

    showModal = (e) => {
        e&&e.stopPropagation()
        this.setState({
            visible: true,
            loading: false,
            options: []
        })
    };

    handleReturn = () => {
        stub.ui.helper.back()
    };

    handleRelieveShow = (e) => {
        e&&e.stopPropagation()
        this.tips.show()
    }

    handleRelieve = (e) => {
        e&&e.stopPropagation()
        stub.api.post('/institutionRelation/changeStatus', {
            id: this.state.data.id,
            relationStatus: this.state.data.relationStatus ? 0 : 1
        }).then(res => {
            this.setState({
                data: {
                    ...this.state.data,
                    relationStatus: this.state.data.relationStatus ? 0 : 1
                }
            })
        })
    }

    render() {
        const { data } = this.state
        if (data) {
            return (
                <div className="ui-content institution-depend-health-care">
                    <div className="institution-depend-health-care-content">
                        <div>
                            <Typography.Title level={4} className="ui-tag-view">
                                {data.name}
                                <Tag className="ui-tag-view-base medium" color="magenta">{this.state.data.relationStatus ? '挂靠生成' : '挂靠解除'}</Tag>
                            </Typography.Title>
                            <Typography.Text>
                                机构编码： {data.code}
                            </Typography.Text>
                        </div>
                        <div style={{background: "#FFF"}}>
                            <Button type="primary" ghost onClick={this.handleRelieveShow}>{
                                this.state.data.relationStatus ? '解除挂靠' : '恢复挂靠'
                            }</Button>
                        </div>
                    </div>
                    <div className="institution-depend-health-care-footer">
                        <Button type='primary' onClick={this.showModal}>
                            修改挂靠
                        </Button>
                        <Button onClick={this.handleReturn}>返回</Button>
                    </div>

                    <AddModal
                        onCancel={this.handleCancel}
                        visible={this.state.visible}
                        options={this.state.options}
                        onSubmit={this.handleSubmit}
                        onSearch={this.handleSearch}
                        loading={this.state.loading}
                    />
                    <stub.ui.Tip
                        onRef={tips => this.tips = tips}
                        tips={stub.filter.transform('确定改变挂靠关系？')}
                        onOk={this.handleRelieve}/>
                </div>
            )
        }
        return (
            <div className="ui-content institution-depend-health-care-no-content-wrapper">
                <div style={{textAlign: 'center'}}>
                    <div>
                        <span>该⻔店尚未挂靠医院，可进⾏挂靠</span>
                    </div>
                    <Button icon="swap" type='primary' onClick={this.showModal}>
                        挂靠
                    </Button>
                </div>
                <Button onClick={this.handleReturn}>返回</Button>
                <AddModal
                    onCancel={this.handleCancel}
                    visible={this.state.visible}
                    options={this.state.options}
                    onSubmit={this.handleSubmit}
                    onSearch={this.handleSearch}
                    loading={this.state.loading}
                />
            </div>

        )
    }
}

export default DependOnHealthCare
