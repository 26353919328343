import React from 'react'

export default {
    key: 'product-change-isFlow-action',
    order: 2000,
    build(stub) {
        class ChangeStatus extends React.Component{

            handSubmit = (e) => {
                const { model, field: { formatter: { args: { anchor, url } } }, page } = this.props;
                const dataToSubmit = {
                    id: model.id,
                    [anchor]: !model[anchor]
                }
                e&&e.stopPropagation();
                stub.api.post(url, dataToSubmit).then(res => {
                    model[anchor] = !model[anchor]
                    page.data = { ...page.data }
                    stub.store.dispatch({type: stub.constant.actionTypes.DUMMY_ACTION})
                })
            };
            render() {
                const { model, field: { formatter: { args: { anchor } } } } = this.props;
                return(
                    <React.Fragment>
                        {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                        {model[anchor] ? <a href="javascript:" onClick={(e) => {
                                e&&e.stopPropagation();
                                this.relieveTips.show()
                            }}>计提失效</a> :
                            // eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid
                            <a href="javascript:" onClick={(e) => {
                                e&&e.stopPropagation()
                                this.againTips.show()
                            }}>计提生效</a>}
                        <stub.ui.Tip
                            key="again"
                            onRef={ref => this.againTips = ref}
                            tips={stub.filter.transform('确定计提生效？')}
                            onOk={this.handSubmit}/>
                        <stub.ui.Tip
                            key="relieve"
                            onRef={ref => this.relieveTips = ref}
                            tips={stub.filter.transform('确定计提失效？')}
                            onOk={this.handSubmit}/>
                    </React.Fragment>
                )
            }
        }
        return ChangeStatus
    }
}