import security from './security'
import StepThird from "../view/institution/StepThird";
import DependOnPharmacy from "../view/institution/DependOnPharmacy";
import DependOnHealthCare from "../view/institution/DependOnHealthCare";


export default {
    security,
    institution: {
        stepThird: StepThird,
        detailAttributes: {
            HealthCare: [{
                key: "dependOnPharmacy",
                component: DependOnPharmacy,
                text: "挂靠药店"
            }],
            Pharmacy: [{
                key: "dependOnHealthCare",
                component: DependOnHealthCare,
                text: "挂靠医院",
                conditions: [{
                    field: "isDtp",
                    value: 1
                }]
            }],
            showDependOnHealthCare: true,
            showDependOnPharmacy: true,
            showDepartment: false,
            showDownstreamStore: false,
            showChainStore: false
        },
    },
    "institution-pharmacy": {
        sceens: [ "institution-pharmacy-list", "institution-pharmacy-filter" ],
        "institution-pharmacy-list": {
            load: "/institutionRelation/load",
            search: "/institutionRelation/search"
        },
    },
    staff: {
        sceens: ['external-staff-list', 'external-staff-edit', 'external-staff-detail', 'external-staff-filter'],
    },
    resource: {
        login: {
            backgroundUrl: '/assets/image/login-masking.png',
            leftLogoUrl: '/assets/image/bg-logo.png',
        }
    }
}
