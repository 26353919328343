import React,{ Component } from 'react'
import {stub} from '@xsk/frontend-skeleton'

class ProductRelative extends Component{
    render() {
        const selectOptions = {
            selectable: false,
        }
        return (
            <stub.ui.ListPage
                listMetadataKey="product-mapping-list"
                filterMetadataKey="product-mapping-filter"
                module="productMapping"
                fixable={false}
                {...this.props}
                selectOptions={selectOptions} />
        )
    }
}

export default ProductRelative