import React, { Component } from 'react';
import { Modal, Form, Input, DatePicker, Button, message } from 'antd';
import moment from 'moment';

export default {
    key: 'SalesBackAdd',
    order: 2040,
    build(stub) {
        class SalesBackAdd extends Component{
            constructor(props) {
                super(props);
                this.model = {};
                this.state = {
                    visible: false
                }
            }

            componentDidMount() {
                this.props.onRef(this);
            }

            onCancel = () => {
                this.show(false)
            };

            onOk = (e) => {
                e.stopPropagation();
                this.props.form.validateFields((err, values) => {
                    if (!err) {
                        const { page, updateRecord, field, data } = this.props;
                        const { formatter } = field || {};
                        const { args } = formatter || {};
                        const { type } = args || {};
                        const params = {
                            returnMoneyAmount: +values.returnMoneyAmount,
                            returnMoneyNumber: +values.returnMoneyNumber,
                            returnMoneyDate: moment(values.returnMoneyDate, 'YYYY-MM-DD').valueOf(),
                            remark: values.remark,
                            purchaseId: (type && type === 'action') ? data.id : page.model.id
                        };
                        if(((params.returnMoneyNumber < 0) && (params.returnMoneyAmount > 0)) ||
                            ((params.returnMoneyNumber > 0) && (params.returnMoneyAmount < 0)) ) {
                            message.error('回款数量、回款金额填写错误');
                            return false;
                        }else if((params.returnMoneyNumber === 0) && (params.returnMoneyAmount === 0)) {
                            message.error('回款金额和回款数量不能同时为0！');
                            return false;
                        }
                        stub.api.post(stub.module.dataMaintenance.backMoneyAdd, params).then(res => {
                            this.onCancel();
                            if(res) {
                                if(type && type === 'action') {
                                    stub.ui.helper.addTabPage({
                                        component: stub.module['dataMaintenance']['dataMaintenance-salesBack-list'].salesBackComponentDetail,
                                        title: '回款详情',
                                        model: data
                                    });
                                    page.loaded = false;
                                }else {
                                    page.data = {...res.data.result};
                                    updateRecord && updateRecord(res);
                                    stub.store.dispatch({type: stub.constant.actionTypes.DUMMY_ACTION})
                                }
                            }
                        })
                    }
                });
            };

            show = isVisible => {
                this.setState({visible: isVisible})
            };

            handleMoneyAmount = e => {
                const { data: { selNumber, amount } } = this.props;
                const value = e.target.value.split('.');
                let decimal = value[1];
                const backAmount = +amount - ((selNumber && selNumber.backAmount) ? selNumber.backAmount : 0);
                if(decimal && decimal.length > 4) decimal = decimal.substr(0, 4);
                const result = decimal ? `${value[0]}.${decimal}` : value[0];
                e.target.value = (value > backAmount) ? backAmount : result;
            };

            handleMoneyNumber = e => {
                const value = e.target.value;
                const { data: { selNumber, sendGoodsNumber } } = this.props;
                const backNumber = sendGoodsNumber - ((selNumber && selNumber.backNumber) ? selNumber.backNumber : 0);
                if(value > backNumber) e.target.value = backNumber;
            };

            render() {
                this.model = {...this.props.model};
                const { getFieldDecorator } = this.props.form;
                const btns = [
                    <Button key="back" onClick={this.onCancel}>
                        {stub.filter.transform('common.cancel')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.onOk}>
                        {stub.filter.transform('common.submit')}
                    </Button>
                ];
                return(
                    <Modal
                        destroyOnClose={true}
                        visible={this.state.visible}
                        title='回款登记'
                        onCancel={this.onCancel}
                        footer={btns}>
                        <div className='salesBack-add-container'>
                            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onSubmit={this.handleSubmit}>
                                <Form.Item label="回款金额">
                                    {getFieldDecorator('returnMoneyAmount', {
                                        rules: [{ required: true, message: '请输入回款金额' }]
                                    })(<Input type='number' onChange={this.handleMoneyAmount} placeholder='请输入回款金额' />)}
                                </Form.Item>
                                <Form.Item label="回款数量">
                                    {getFieldDecorator('returnMoneyNumber', {
                                        rules: [{required: true, message: '请输入回款数量'}]
                                    })(<Input type='number' onChange={this.handleMoneyNumber} placeholder='请输入回款数量' />)}
                                </Form.Item>
                                <Form.Item label="回款日期">
                                    {getFieldDecorator('returnMoneyDate', {
                                        rules: [{ required: true, message: '请选择回款日期' }]
                                    })(<DatePicker />)}
                                </Form.Item>
                                <Form.Item label="备注">
                                    {getFieldDecorator('remark', {
                                        rules: [],
                                    })(<Input.TextArea placeholder='备注' />)}
                                </Form.Item>
                            </Form>
                        </div>
                    </Modal>
                )
            }
        }
        return Form.create()(SalesBackAdd);
    }
}
