import React from 'react';
import { stub } from '@xsk/frontend-skeleton';

class ExceptionReminder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return(
            <stub.ui.ListPage
                module='report'
                {...this.props}
                listMetadataKey='report-exceptionReminder-list'
                filterMetadataKey='report-exceptionReminder-filter'/>
        )
    }

}

export default ExceptionReminder;
