import React from 'react';
import { stub } from '@xsk/frontend-skeleton';

class SendGoodsDetail extends React.Component {
    render() {
        return <stub.ui.DetailPage module="collection" sceenKey='collection-sendGoods-detail' {...this.props} />
    }

}

export default SendGoodsDetail;
