import React, { Component } from 'react';
import { Modal, Table, Button } from 'antd';
import moment from 'moment';

export default {
    key: 'AdjustHistory',
    order: 2040,
    build(stub) {
        class AdjustHistory extends Component{
            constructor(props) {
                super(props);
                this.state = {
                    visible: false
                };
                this.columns = [
                    {
                        title: '序号',
                        key: 'serial',
                        render: (title, row, index) => index + 1
                    },
                    {
                        title: '期末结余回款调整前',
                        dataIndex: 'adjustBeforeNumber',
                        key: 'adjustBeforeNumber'
                    },
                    {
                        title: '期末结余回款调整后',
                        dataIndex: 'adjustAfterNumber',
                        key: 'adjustAfterNumber'
                    },
                    {
                        title: '调整说明',
                        dataIndex: 'explain',
                        key: 'explain'
                    },
                    {
                        title: '调整人员',
                        dataIndex: 'adjustName',
                        key: 'adjustName'
                    },
                    {
                        title: '修改时间',
                        dataIndex: 'createdDate',
                        key: 'createdDate',
                        render(value) {
                            return moment(value).format("YYYY-MM-DD h:mm:ss")
                        }
                    }
                ];
            }

            componentDidMount() {
                this.props.onRef(this);
            }

            onCancel = () => {
                this.show(false);
            };

            show = isVisible => {
                this.setState({visible: isVisible});
            };

            render() {
                const btns = [<Button key="submit" type="primary" onClick={this.onCancel}>确定</Button>];
                return(
                    <Modal
                        width='60%'
                        visible={this.state.visible}
                        title='调整历史'
                        onCancel={this.onCancel}
                        onOk={this.onOk}
                        footer={btns}>
                        <Table className='report-adjust-history-table' pagination={false} rowKey={(item, index) => index} dataSource={this.props.adjustHistoryData} columns={this.columns} />
                    </Modal>
                )
            }
        }
        return AdjustHistory;
    }
}
