import { SalesBack} from '../components/index';
import {TerminalRelative} from '../components/index'
import {ProductRelative} from  '../components/index'

const addMenu = [
    {
        key: 'dataMaintenance',
        text: '数据维护',
        order: 30,
        icon: 'database',
        children: [
            {
                key: 'salesBack',
                text: '回款数据',
                order: 10,
                component: SalesBack,
                meta: {
                    permissions: 'salesback.load'
                }
            }, {
                key: 'terminalRelative',
                text: '终端对应关系',
                order: 20,
                component: TerminalRelative,
                meta: {
                    permissions: 'terminalRelative.load'
                }
            }, {
                key: 'productRelative',
                text: '产品对应关系',
                order: 30,
                component: ProductRelative,
                meta: {
                    permissions: 'productRelative.load'
                }
            }
        ]
    }
];

export default addMenu
