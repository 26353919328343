import React,{ Component } from 'react'
import {stub} from '@xsk/frontend-skeleton'

class HealthCare extends Component{
    render() {
        const defaultFilter = {
            category: "HealthCare"
        };
        return (
            <stub.ui.ListPage module="institution" {...this.props} defaultFilter={defaultFilter}/>
        )
    }
}

class Pharmacy extends Component{
    render() {
        const defaultFilter = {
            category: "Pharmacy"
        };
        return (
            <stub.ui.ListPage module="institution" {...this.props}  defaultFilter={defaultFilter}/>
        )
    }
}

class Distributor extends Component{
    render() {
        const defaultFilter = {
            category: "Distributor"
        };
        return (
            <stub.ui.ListPage module="institution"  {...this.props}  defaultFilter={defaultFilter}/>
        )
    }
}


export  { HealthCare, Pharmacy, Distributor}
