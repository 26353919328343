import React from 'react'
import { Modal, message } from 'antd'

export default {
    key: 'jurisdiction-customer-edit-action',
    order: 2500,
    build(stub, deco={}){
        class EditModal extends React.Component{
            constructor(props){
                super(props)
                this.state = {
                    visible: false,
                    loading: false
                }
                this.model = {}
            }

            onCancel = (e) => {
                e&&e.stopPropagation()
                this.setState({
                    visible: false
                })
            };

            showModal = (e) => {
                this.setState({
                    visible: true,
                    loading: false
                })
                this.model.customerId = this.props.record.customerId
            };

            handleSubmit = (e) => {
                const { field: { formatter: { args: { sceenKey, module, url } } }, page, record } = this.props;
                const listSceenKey = this.props.sceenKey
                const dataToSubmit = {
                    criterias: {
                        filter: page.data.filter,
                        page: page.data.page ? {
                            pageSize: page.data.page.pageSize,
                            current: 1
                        } : undefined,
                        sort: page.data.sort
                    },
                    [module]: {
                        jurisdictionStaffId: record.jurisdictionStaffId,
                        oldCustomerId: record.customerId,
                        ...this.model
                    }
                }
                e&&e.stopPropagation()
                this.form.validateFields((errors, values) => {
                    if (!errors){
                        this.setState({
                            loading: true
                        })
                        stub.api.post(url, dataToSubmit, {
                            headers: {
                                currentSceens: `${sceenKey}|${listSceenKey}`,
                                supressErrorHandle: true
                            }
                        }).then(res => {
                            page.data = {...res.data.result}
                            this.setState({
                                loading: false,
                                visible: false
                            })
                            stub.store.dispatch({type: stub.constant.actionTypes.DUMMY_ACTION})
                            message.success(stub.filter.transform('common.web.submit.success'))
                        }).catch(errors => {
                            this.setState({
                                loading: false
                            })
                            Modal.error({
                                title: stub.filter.transform('common.error'),
                                content: stub.filter.transform(errors.error.message || `system.error.${errors.error.code}`)
                            })
                        })
                    }
                })
            }

            render() {
                const { field: { formatter: { args: { sceenKey, title } }, label } } = this.props;
                const { visible, loading } = this.state
                const options = {
                    model: this.model,
                    onRef: (group, form) => this.form = form,
                    unCollpased: true,
                    hideNormalAction: true,
                    formItemCol: 24
                }

                return(
                    <React.Fragment>
                        <a onClick={this.showModal} href="javascript:">
                            {stub.filter.transform(label)}
                        </a>
                        <Modal
                            title={title}
                            onCancel={this.onCancel}
                            onOk={this.handleSubmit}
                            confirmLoading={loading}
                            visible={visible}
                            okText={stub.filter.transform("common.submit")}
                        >
                            <stub.ui.SceneContainer sceneKey={sceenKey} options={options}/>
                        </Modal>
                    </React.Fragment>
                )
            }
        }
        return EditModal
    }
}

