import React, { Component } from 'react';
import { Modal, Form, Input } from 'antd';

export default {
    key: 'AdjustNumber',
    order: 2040,
    build(stub) {
        class AdjustNumber extends Component{
            constructor(props) {
                super(props);
                this.model = {};
                this.state = {
                    data: {},
                    tipsMessage: '',
                    visible: false
                }
            }

            componentDidMount() {
                this.props.onRef(this);
                this.model = this.props.model;
            }

            onCancel = () => {
                this.show(false)
            };

            onOk = (e) => {
                e.stopPropagation();
                this.props.form.validateFields((err, data) => {
                    if (!err) {
                        this.tips.show(true);
                        this.setState({
                            data: data,
                            tipsMessage: `确定当前期末结余回款值修改为${data.adjustAfterNumber}？`
                        })
                    }
                });
            };

            handleTips = () => {
                const { model, adjustNumberData } = this.props;
                const params = {
                    adjustAfterNumber: +this.state.data.adjustAfterNumber,
                    adjustBeforeNumber: model.endBackTotal,
                    explain: this.state.data.explain ? this.state.data.explain : '',
                    financialFlowId: model.id
                };
                stub.api.post(stub.module.flowReport.adjustNumber, params).then(res => {
                    this.onCancel();
                    adjustNumberData(this.state.data.adjustAfterNumber);
                })
            };

            show = isVisible => {
                this.setState({visible: isVisible})
            };

            handleEndBackTotal = e => {
                const value = e.target.value;
                const { model: { endBackInitial } } = this.props;
                if(value > endBackInitial) {
                    e.target.value = endBackInitial;
                }else if(value < 0) {
                    e.target.value = 0;
                }
            };

            render() {
                this.model = {...this.props.model};
                const { getFieldDecorator } = this.props.form;
                const endBackInitial = this.model.endBackInitial ? this.model.endBackInitial : 0;
                return(
                    <div>
                        <stub.ui.Tip onRef={ref => this.tips = ref}
                                     tips={this.state.tipsMessage}
                                     onOk={this.handleTips} />
                        <Modal
                            destroyOnClose={true}
                            visible={this.state.visible}
                            title='期末结余回款调整'
                            onCancel={this.onCancel}
                            onOk={this.onOk} >
                            <div className='salesBack-add-container'>
                                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} onSubmit={this.handleSubmit}>
                                    <Form.Item label="调整前数量">
                                        {getFieldDecorator('adjustBeforeNumber', {initialValue: endBackInitial})(<Input type='number' disabled={true} />)}
                                    </Form.Item>
                                    <Form.Item label="调整后数量">
                                        {getFieldDecorator('adjustAfterNumber', {
                                            rules: [{required: true, message: '请输入调整后数量'}]
                                        })(<Input type='number' onChange={this.handleEndBackTotal} placeholder='请输入调整后数量' />)}
                                    </Form.Item>
                                    <Form.Item label="调整说明">
                                        {getFieldDecorator('explain', {rules:[]})(<Input.TextArea placeholder='调整说明' />)}
                                    </Form.Item>
                                </Form>
                            </div>
                        </Modal>
                    </div>
                )
            }
        }
        return Form.create()(AdjustNumber);
    }
}
