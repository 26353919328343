import React from 'react'
import { message, Button, Modal, Icon } from 'antd'

export default {
    key: 'sales-batch-delete-action',
    order: 2500,
    build(stub, deco) {
        class SalesBatchDelete extends React.Component {

            constructor(props) {
                super(props);
                this.state = {
                    serialNumber: [],
                    visible: false,
                    ids: []
                }
            }

            handleClick = (e) => {
                e&&e.stopPropagation();
                const { page: { data: { selectedRows } }, field: { formatter: {args: { unSelectedMessage }} } } = this.props;
                let serialNumber = [];
                let ids = [];
                (selectedRows || []).forEach(item => {
                    if(item.returnMoneyTotalAmount && item.returnMoneyTotalAmount > 0) {
                        serialNumber.push(item.serialNumber);
                    }
                    ids.push(item.id);
                });
                this.setState({
                    serialNumber,
                    ids
                });
                if (!selectedRows || selectedRows.length < 1) {
                    message.info(stub.filter.transform(unSelectedMessage || 'common.web.unSelectedMessage'))
                } else {
                    this.setState({
                        visible: true
                    })
                }
            };

            handleOk = () => {
                const { page, field: { formatter: {args: { url } } } } = this.props;
                stub.api.post(url, {
                    model: { ids: this.state.ids},
                    criterias: {
                        filter: page.data.filter,
                        page: page.data.page,
                        sort: page.data.sort
                    }
                }).then(res => {
                    page.data = {...res.data.result};
                    stub.store.dispatch({type: stub.constant.actionTypes.DUMMY_ACTION});
                    this.onCancel();
                })
            };

            onCancel = () => {
                this.setState({
                    visible: false
                })
            };

            render() {
                const { field: { label } } = this.props;
                const serialNumberLength = this.state.serialNumber.length > 0;
                return(
                    <React.Fragment>
                        <Button onClick={this.handleClick}>{stub.filter.transform(label)}</Button>
                        <Modal
                            destroyOnClose={false}
                            visible={this.state.visible}
                            title={stub.filter.transform('common.tips')}
                            okText= {stub.filter.transform('common.web.ok')}
                            cancelText= {stub.filter.transform('common.web.cancel')}
                            onOk={this.handleOk}
                            onCancel={this.onCancel}>
                            <div className='ui-tip-content'>
                                <div>
                                    <Icon type='info-circle' theme='twoTone' style={{fontSize: '36px'}} />
                                </div>
                                <p>{serialNumberLength ? '删除发货数据将一并删除回款信息！' : '确定删除发货数据？'}</p>
                                {
                                    serialNumberLength ? <div className="sendGoods-batchDelete-serial-number-title">流水号：</div> : null
                                }
                                <div className='sendGoods-batchDelete-serial-number'>
                                    {
                                        this.state.serialNumber.map((item, index) => {
                                            return (<span key={index}>{item}</span>)
                                        })
                                    }
                                </div>
                            </div>
                        </Modal>
                    </React.Fragment>
                )
            }
        }

        return SalesBatchDelete
    }
}
