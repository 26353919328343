import {start} from '@xsk/frontend-skeleton'
import fkComponents from '@xsk/frontend-framework-web'
import staffComponents from '@xsk/frontend-staff-web'
import productComponents from '@xsk/frontend-product-web'
import organizationComponents from '@xsk/frontend-institution-web'
import customizedComponents from '../../modules'
import merge from 'deepmerge'
import {Modal} from 'antd'
import route from '../route'
import { menu } from '../structure'
import constant from '../constant'
import initialState from '../initialState'
import metadata from '../metadata'
import module from '../module'
import "../../style/basic.less";

let decorators = {
  constant: {
    overrideObject: constant
  },
  initialState: {
    overrideObject: initialState
  },
  route: {
    overrideObject: route
  },
  structure: {
    menu: {
      overrideObject: menu.initMenu,
      remove: menu.removedMenu,
      add: menu.addMenu,
      update: menu.updateMenu,
      move: menu.moveMenu
    }
  },
  metadata: {
    decorators:{
      getInstitutionLabel: (label, model) => {
        if (model && model.category === "Distributor")  return "所在地";
        return "机构归属地"
      }
    }
  },
  ui: {
    metadata: {
      moreMetadataComponents: metadata
    },
    GeneralLayout: {
      footerComponent: null
    },
    TopHeader: {
      displaySearch: false,
      displayNotification: false
    },
    Table: {
      fixedThead: true
    },
    Tabs:{
      maxCount: 20,
      reachMaxFunc: ()=> {
        Modal.info({
          title: '提示',
          content: '页签已达到最大数量'
        })
      }
    }
  },
  module: {
    ...module
  }
};

let injectConfig = merge.all([fkComponents, staffComponents, productComponents, organizationComponents, customizedComponents, {decorators}])
start(injectConfig)
