import React, { Component } from 'react'
import { Modal, Icon, Divider, Descriptions } from 'antd'

export default {
    order: 2000,
    key: 'jurisdiction-delete-action',
    build(stub, deco) {
        class JurisdictionDelete extends Component{

            constructor(props){
                super(props)
                this.state = {
                    visible: false,
                    loading: false,
                    data: []
                }
            }

            handleDelete = () => {
                const { model } = this.props
                stub.api.get("/sales/jurisdiction/customer/list", {
                    params: { id: model.id }
                }).then(res => {
                    this.setState({
                        data: res.data.result,
                        visible: true,
                        loading: false
                    })
                })
            };

            hideModal = (e) => {
                e&&e.stopPropagation()
                this.setState({
                    visible: false
                })
            };

            handleOk = (e) => {
                e&&e.stopPropagation();
                const { model, page, field: {formatter: {args: {url}}} } = this.props;
                this.setState({
                    loading: true
                })
                stub.api.post(url, {
                    model: {
                        id: model.id
                    },
                    criterias: {
                        filter: page.data.filter,
                        page: page.data.page ? {
                            pageSize: page.data.page.pageSize,
                            current: 1
                        } : undefined,
                        sort: page.data.sort
                    }
                }).then(res => {
                    this.setState({
                        visible: false
                    });
                    page.data = res.data.result;
                    stub.store.dispatch({type: stub.constant.actionTypes.DUMMY_ACTION})
                })
            };

            render() {
                const { data, loading, visible } = this.state;
                return(
                    <React.Fragment>
                        <a href="javascript:" onClick={this.handleDelete}>
                            删除辖区
                        </a>
                        <Modal title="提示" visible={visible} onCancel={this.hideModal} onOk={this.handleOk} okText="确认删除" confirmLoading={loading}>
                            <div className='ui-tip-content'>
                                <div>
                                    <Icon type='info-circle' theme='twoTone' style={{fontSize: '36px'}} />
                                    <p><b>辖区存在客户信息</b></p>
                                    <p>刪除辖区，将会一并删除该辖区下的所有客户，及终端、产品对应关系！</p>
                                </div>
                            </div>
                            <Divider/>
                            <Descriptions column={2}>
                                {data.map(t => (
                                    <Descriptions.Item key={t.value} label="客户">
                                        {t.label}
                                    </Descriptions.Item>
                                ))}
                            </Descriptions>
                        </Modal>
                    </React.Fragment>
                )
            }
        }

        return JurisdictionDelete
    }
}
