import React, { Component } from 'react'
import merge from 'deepmerge'
import { stub } from '@xsk/frontend-skeleton'

class DependOnPharmacy extends Component {
    constructor(props){
        super(props);
        props.page.params = merge({}, props.page.params);
        props.page.params.healthCareId = props.page.params.id;
        delete props.page.params.id;
        delete props.page.params.category
    }
    render() {
        return (
            <stub.ui.ListPage
                module="institution-pharmacy"
                listMetadataKey="institution-pharmacy-list"
                filterMetadataKey="institution-pharmacy-filter"
                hidePagination={true}
                {...this.props}
            />
        )
    }
}

export default DependOnPharmacy
