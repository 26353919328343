import React from 'react';
import { stub } from '@xsk/frontend-skeleton';
import Chart from '../Chart';

class NumberHospitals extends React.Component {
    render() {
        return(
            <stub.ui.ListPage
                module='numberHospitals'
                {...this.props}
                fixable={true}
                middleContent={Chart}
                listMetadataKey='report-numberHospitals-list'
                filterMetadataKey='report-numberHospitals-filter' />
        )
    }

}

export default NumberHospitals;
