import React from 'react';
import { Modal } from 'antd';

export default {
    key: 'paymentDay-action',
    build(stub) {
        class PaymentDaysAction extends React.Component {
            constructor(props) {
                super(props);
                this.state = {
                    visible: false,
                    paymentDay: '',
                    model: JSON.parse(JSON.stringify(props.model))
                };
            }

            handleCancel = () => {
                this.visible(false)
            };

            visible = visible => {
                const { model } = this.props;
                model.paymentDay = this.state.model.paymentDay;
                this.setState({visible: visible})
            };

            validate = () => {
                let result = true;
                if(this.form) {
                    this.form.validateFields((err) => {
                        if (err) {
                            result = false
                        }
                    })
                }
                return result
            };

            handleOk = e => {
                e.stopPropagation();
                if (this.validate()) {
                    const { page, updateRecord, model } = this.props;
                    this.setState({
                        model: JSON.parse(JSON.stringify(model))
                    })
                    stub.api.post(stub.module.dataMaintenance.paymentDayUpdate, {id: model.id, paymentDay: model.paymentDay}).then(res => {
                        if(res) {
                            this.handleCancel();
                            page.data = {...res.data.result};
                            updateRecord && updateRecord(res);
                            stub.store.dispatch({type: stub.constant.actionTypes.DUMMY_ACTION})
                        }
                    })
                }
            };

            render() {
                const { field: { label }, model } = this.props;
                return (
                    <div style={{display: 'inline-block'}}>
                        <a href="javascript:" onClick={() => {this.visible(true)}}>{label}</a>
                        <Modal
                            title="账期"
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            confirmLoading={null}
                            onCancel={this.handleCancel}>
                            <stub.ui.SceneContainer
                                sceneKey='dataMaintenance-paymentDay-edit'
                                options={{ model: model, unCollpased: true, onRef: (group,form) => {this.form = form}}}
                            />
                        </Modal>
                    </div>
                )
            }
        }

        return PaymentDaysAction;
    }
}
