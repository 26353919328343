import React, { Component } from 'react'

export default {
	key: 'productSetToInvalid-action',
	order: 1210,
	build(stub, deco, decorators) {
		class productSetToInvalid extends Component{

			constructor(props){
				super(props)
				this.state = {
					modalVisible: false
				}
			}


			showModal = () => {
				this.model={}
				this.setState({
					modalVisible: true
				})
			};

			hideModal = () => {
				this.setState({
					modalVisible: false
				})
			};


			setInvalid = ( ) => {
				const { record, field, field:{formatter: {args}}, sceenKey, page } = this.props
				const  url = this.props.field && this.props.field.formatter && this.props.field.formatter.args && this.props.field.formatter.args.url
				record[args.anchor] = 0;
				const pair = stub.metadata.getDeclaredValues(record, sceenKey, field.key)
				stub.api.get(
					url, {
						params: {
							status: pair.status,
							id: pair.id
						}
					}
				).then(res => {
					this.hideModal();
					page.data = {...page.data};
					stub.store.dispatch({type: stub.constant.actionTypes.DUMMY_ACTION})
				})
			};

			render() {
				const { field } = this.props;
				const { formatter } = field;
				const { args } = formatter;
				const { invalidLabel } = args;


					return (
						<div style={{display: 'inline-block'}} onClick={e => e.stopPropagation()}>
							<a href='javascript:' onClick={() => {this.tips.show()}}>{stub.filter.transform(invalidLabel)}</a>
							<stub.ui.Tip onRef={ref => this.tips = ref} tips={stub.filter.transform('productMapping.web.setInvalidTips')} onOk={(e)=>this.setInvalid(e)}/>
						</div>
					)
			}
		}

		return productSetToInvalid
	}
}
