import React from 'react';

export default {
    key: 'salesBackAdd-action',
    build(stub) {
        class SalesBackAddAction extends React.Component {
            constructor(props) {
                super(props);
                this.state = {
                    data: {},
                    model: {}
                };
            }

            onClick = e => {
                e.stopPropagation();
                const { model } = this.props;
                stub.api.get(stub.module.dataMaintenance.selNumber, {params: {id: model.id}}).then(res => {
                    if(res) {
                        const { model } = this.props;
                        model.selNumber = res.data.result;
                        this.setState({
                            data: model
                        });
                        this.tips.show(true);
                    }
                })
            };

            render() {
                const { field, field: { label }, page } = this.props;
                return (
                    <div style={{display: 'inline-block'}}>
                        <span style={{color: '#FF9300', cursor: 'pointer'}}
                              onClick={e => {
                                  this.onClick(e)
                              }}>{stub.filter.transform(label)}</span>
                        <stub.ui.SalesBackAdd
                            data={this.state.data}
                            model={this.state.model}
                            field={ field }
                            page={ page }
                            onRef={ref => this.tips = ref}
                            onOk={() => this.onOk()} />
                    </div>)
            }
        }

        return SalesBackAddAction;
    }
}
