import React,{ Component } from 'react'

class Copyright extends  Component{
    render() {
        return <div className='ui-login-bottomInfo-copyright'>
            <span>copyright </span>
            <span>© </span>
            <span>2019 上海软素科技有限公司. All rights reserved.</span>
        </div>
    }
}

export default Copyright
