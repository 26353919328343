import React from 'react';
import { stub } from '@xsk/frontend-skeleton';
import { Button } from 'antd';

class SaleDataDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {}
        };
    }

    componentWillMount() {
        stub.api.get(stub.module.cleanSaleData.saleDataDetailUrl, {params: {id: this.props.page.model.id}}).then(res => {
            if (res) {
                this.dataHandle(res);
            }
        })
    }

    dataHandle = res => {
        this.setState({
            data: res.data.result
        })
    };

    render() {
        return (
            <div className='salesBackDetail-container'>
                <stub.ui.SceneContainer
                    module="cleanSaleData"
                    options={{...this.props, model: this.state.data}}
                    sceneKey='cleanData-saleData-detail'/>

                <div className='salesBackDetail-addBtn salesBackDetail-addBtnBack'>
                    <Button onClick={() => {
                        stub.ui.helper.back()
                    }}>{stub.filter.transform('common.return')}</Button>
                </div>
            </div>
        )
    }

}

export default SaleDataDetail;
