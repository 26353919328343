import JurisdictionStaff from "../components/JurisdictionStaff";
import JurisdictionCustomer from "../components/JurisdictionCustomer";

export default {
    add: [
        {
            parentKey: 'masterMgr',
            key: 'JurisdictionManger',
            text: '辖区管理',
            order: 60,
            children: [{
                key: 'JurisdictionStaff',
                component: JurisdictionStaff,
                order: 10,
                text: '非销售辖区-人员',
                meta: {
                    permissions: 'businessarea.load'
                }
            }, {
                key: 'JurisdictionCustomer',
                component: JurisdictionCustomer,
                order: 20,
                text: '销售辖区-人员-客户',
                meta: {
                    permissions: 'salesarea.load'
                }
            }]
        }
    ]
}
