import React from 'react';
import { Modal, Input, message } from 'antd';

export default {
    key: 'registerStatus-action',
    build(stub) {
        class RegisterStatusAction extends React.Component {
            constructor(props) {
                super(props);
                this.state = {
                    visible: false,
                    remark: '',
                    returnMoneyNumber: 0
                };
            }

            handleCancel = () => {
                this.visible(false)
            };

            visible = visible => {
                this.setState({visible: visible});
                this.setState({
                    remark: ''
                })
            };

            handleOk = () => {
                const { data, record, page: { model } } = this.props;
                const options = {
                    id: record.id,
                    purchaseId: record.purchaseId,
                    remark: this.state.remark,
                    status: record.status === 1 ? 0 : 1
                };
                const returnMoneyNumber = this.state.returnMoneyNumber + record.returnMoneyNumber;
                if((returnMoneyNumber > +model.sendGoodsNumber) && (options.status === 1)) {
                    message.error('回款总数量大于发货数量');
                }else {
                    stub.api.post(stub.module.dataMaintenance.backMoneyStatus, options).then(res => {
                        if(res) {
                            data.dataHandle(res);
                            this.handleCancel();
                        }
                    })
                }
            };

            onChange = (event) => {
                this.setState({
                    remark: event.target.value
                })
            };

            showModal = () => {
                const { data: { list }, model } = this.props;
                const len = list.length;
                this.visible(true);
                if(len === 0) return false;
                let res = 0;
                list.forEach(item => {
                    if(item.status) {
                        res += item.returnMoneyNumber;
                    }
                });
                this.setState({
                    returnMoneyNumber: res,
                    remark: model.remark
                });
            };

            render() {
                const { model } = this.props;
                const status = !!model.status ? '失效' : '生效';
                return (
                    <div style={{display: 'inline-block'}}>
                        <a href="javascript:" onClick={this.showModal}>{status}</a>
                        <Modal
                            title="更改状态"
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}>
                            <p className='salesBack-register-status'>{`确定把状态设为${status}状态？`}</p>
                            {
                                model.status ? (
                                    <div>
                                        <p>原因：</p>
                                        <Input.TextArea value={this.state.remark} onChange={this.onChange} placeholder='请输入失效原因' />
                                    </div>
                                ) : null
                            }

                        </Modal>
                    </div>)
            }
        }

        return RegisterStatusAction;
    }
}
