import React from 'react'
import { Button, message } from 'antd'

export default {
    key: 'jurisdiction-generate-action',
    order: 2500,
    build(stub, deco) {
        return (props) => {
            const { page, field: { formatter: { args: { url, anchor } } } } = props
            const onClick = (e) => {
                e&&e.stopPropagation();
                const model = {
                    [anchor]: page.data.filter[anchor]
                }
                stub.api.post(url, {
                    model,
                    criterias: {
                        filter: page.data.filter,
                        page: page.data.page ? {
                            pageSize: page.data.page.pageSize,
                            current: 1
                        } : undefined,
                        sort: page.data.sort
                    }
                }).then(res => {
                    page.data = res.data.result;
                    page.data = { ...page.data }
                    message.success('辖区生成成功')
                    stub.store.dispatch({type: stub.constant.actionTypes.DUMMY_ACTION})
                })
            }
            return(
                <Button type="primary" onClick={onClick}>生成辖区</Button>
            )
        }
    }
}