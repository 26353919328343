import React, { Component } from 'react'
import { Table, Card, Form, Select, Button, Modal, message } from 'antd'
import { stub } from '@xsk/frontend-skeleton'
import metadata from "./metadata";

const renderProvince = (text) => {
    const options = stub.filter.pocket('province_all_jurisdiction') || []
    const selected = options.find(t => t.value === text)
    return selected && selected.label
};

const AddModal = Form.create({name: 'add-form'})(props => {
    const { form, onCancel, onSubmit, visible, loading } = props;
    const { getFieldDecorator, validateFields } = form;
    const options = stub.filter.pocket('province_jurisdiction') || []
    const handleSubmit = () => {
        validateFields((err, values) => {
            if (!err) {
                onSubmit(values)
            }
        })
    }
    return(
        <Modal title="添加省份" visible={visible} destroyOnClose={true} onOk={handleSubmit} onCancel={onCancel} okText="提交" loading={loading}>
            <Form layout="inline">
                <Form.Item label="省份" labelCol={{span: 6}} wrapperCol={{span: 16}}>
                    {getFieldDecorator('ids', {
                        rules: [{
                            required: true,
                            message: '请选择省份'
                        }],
                        initialValue: []
                    })(
                        <Select
                            showArrow={false}
                            mode="multiple"
                            filterOption={(inputValue, option) => {
                                return option.props.children.search(inputValue) > -1 || (option.props.value.toString().search(inputValue) > -1);
                            }}
                        >
                            {options.map(t => (
                                <Select.Option key={t.value} value={t.value}>
                                    {t.label}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
            </Form>
        </Modal>

    )
})

class Detail extends Component{
    constructor(props){
        super(props);
        this.state = {
            data: null,
            headerData: {},
            currentItem: null,
            selectedRowKeys: [],
            loading: false,
            visible: false
        }
    }

    componentWillMount() {
        stub.metadata.registerMetadata(metadata)
    }

    componentDidMount() {
        this.fetch()
        this.setState({
            headerData: {
                ...this.props.page.params,
                jurisdictionType: '非销售辖区'
            }
        })
    }

    fetch = () => {
        const { jurisdictionStaffId } = this.props.page.params
        stub.api.get('/business/affairs/jurisdiction/province/list', {
            params: {
                jurisdictionStaffId
            }
        }).then(res => {
            this.setState({
                data: res.data.result
            })
        })
    };

    renderAction = (text, record) => {
        if(stub.account.hasPermission("jurisdiction.delete")){
            return(
                // eslint-disable-next-line jsx-a11y/anchor-is-valid,no-script-url
                <a href="javascript:" onClick={() => this.onDelete(record)}>
                    删除
                </a>
            )
        }
        return null
    };

    onDelete = (record) => {
        this.setState({
            currentItem: record
        })
        this.singleTips.show()
    }

    handleClose = (e) => {
        e&&e.stopPropagation()
        this.setState({
            visible: false
        })
    };

    showModal = (e) => {
        e&&e.stopPropagation()
        this.setState({
            visible: true,
            loading: false
        })
    }

    onBatchDelete = () => {
        const { selectedRowKeys } = this.state
        if(selectedRowKeys.length < 1) {
            message.info("请选择需要删除的数据")
        } else {
            this.multipleTips.show()
        }
    }

    handleDelete = () => {
        const { currentItem } = this.state
        stub.api.post('/business/affairs/jurisdiction/province/delete', {
            ids: [ currentItem.id ]
        }).then(res => {
            this.props.page.fromPage.loaded = false;
            this.fetch()
        })
    };

    handleBatchDelete = () => {
        const { selectedRowKeys } = this.state
        stub.api.post('/business/affairs/jurisdiction/province/delete', {
            ids: selectedRowKeys
        }).then(res => {
            this.props.page.fromPage.loaded = false;
            this.fetch()
        })
    };

    handleSubmit = (values) => {
        console.log(values);
        stub.api.post('/business/affairs/jurisdiction/province/add', {
            jurisdictionStaffId: this.props.page.params.jurisdictionStaffId,
            provinceRangeKeys: values.ids
        }).then(res => {
            this.setState({
                loading: false,
                visible: false
            })
            this.props.page.fromPage.loaded = false;
            this.fetch()
        })
    }

    columns = [{
        key: 'number',
        title: '序号',
        render: (text, record, index) => index + 1
    },{
        title: '省份',
        dataIndex: 'provinceRangeKey',
        render: renderProvince
    },{
        title: '操作',
        key: 'action',
        render: this.renderAction
    }];


    onChange = (selectedRowKeys) => {
        this.setState({
            selectedRowKeys
        })
    };

    handleReturn = (e) => {
        e&&e.stopPropagation();
        stub.ui.helper.back()
    };

    render() {

        const selectOptions = {
            onChange: this.onChange,
            selectable: true,
            selectType: 'checkbox',
            selectedRowKeys: this.state.selectedRowKeys
        };

        const { data, visible, loading } = this.state

        const hasPermission = stub.account.hasPermission("jurisdiction.delete")

        return(
            <div className="ui-content">
                <stub.ui.SceneContainer
                    sceneKey="jurisdiction-province-detail"
                    options={{model: this.state.headerData}}
                />
                <Card type="inner" title="负责省份">
                    <Button type="primary" icon="plus" onClick={this.showModal}>添加省份</Button>
                    {hasPermission ? <Button onClick={this.onBatchDelete} style={{marginLeft: '10px'}}>批量删除</Button> : null}
                    <Table
                        dataSource={(data && data.list) || []}
                        columns={this.columns}
                        pagination={false}
                        rowKey="id"
                        rowSelection={selectOptions}
                        style={{marginTop: '10px'}}
                        footer={() => `共${((data && data.list) || []).length}条`}
                    />
                </Card>
                <div style={{textAlign: 'center', marginTop: '10px'}}>
                    <Button onClick={this.handleReturn}>返回</Button>
                </div>
                <AddModal
                    visible={visible}
                    loading={loading}
                    onSubmit={this.handleSubmit}
                    onCancel={this.handleClose}
                />
                <stub.ui.Tip onRef = {ref => this.singleTips = ref} tips="确定将该省份从此辖区中删除？" onOk={this.handleDelete}/>
                <stub.ui.Tip onRef = {ref => this.multipleTips = ref} tips="确定将省份从此辖区中删除？" onOk={this.handleBatchDelete}/>
                {/*<stub.ui.Tips onRef={ref => this.multipleTips = ref}/>*/}
            </div>
        )
    }
}


export default Detail