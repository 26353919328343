import React,{ Component } from 'react'
import {stub} from '@xsk/frontend-skeleton'
import {connect} from 'react-redux'

const mapStateToProps = (state,ownProps) => {
	return{
		others: ownProps.page.others
	}
}

class ExteralList extends Component{

	render() {

		return (
			<div>
				<stub.ui.ListPage
					listMetadataKey="external-staff-list"
					filterMetadataKey="external-staff-filter"
					header={stub.module.staff.listHeader}
					module="staff" {...this.props}
					/>
			</div>
		)
	}
}

export default connect(mapStateToProps) (ExteralList)
