import React, { Component } from 'react'

export default {
	key: 'status-action',
	order: 1210,
	build(stub, deco, decorators) {
		class Status extends Component{

			constructor(props){
				super(props)
				this.state = {
					modalVisible: false
				}
			}


			showModal = () => {
				this.model={}
				this.setState({
					modalVisible: true
				})
			};

			hideModal = () => {
				this.setState({
					modalVisible: false
				})
			};

			setEffective = () => {
				const { record, field, field:{formatter: {args}}, sceenKey, page, module } = this.props
				const effectiveUrl = this.props.field && this.props.field.formatter && this.props.field.formatter.args && this.props.field.formatter.args.effectiveUrl
				const pair = stub.metadata.getDeclaredValues(record, sceenKey, field.key)
				stub.api.post(effectiveUrl, {
					[module]: {
						...pair,
						[args.anchor]:1
					}
				}).then(res => {
					record[args.anchor] = 1;
					this.hideModal();
					page.data = {...page.data};
					stub.store.dispatch({type: stub.constant.actionTypes.DUMMY_ACTION})
				})
			}

			setInvalid = ( ) => {
				const { record, field, field:{formatter: {args}}, sceenKey, page,  module } = this.props
				const  invalidUrl = this.props.field && this.props.field.formatter && this.props.field.formatter.args && this.props.field.formatter.args.invalidUrl
				const pair = stub.metadata.getDeclaredValues(record, sceenKey, field.key)
				stub.api.post(invalidUrl, {
					[module]: {
						...pair,
						[args.anchor]:0
					}
				}).then(res => {
					if(res.error){
						//do nothing
					}else{
						record[args.anchor] = 0;
						this.hideModal();
						page.data = {...page.data};
						stub.store.dispatch({type: stub.constant.actionTypes.DUMMY_ACTION})
					}
				})
			};

			render() {
				const { field, record } = this.props;
				const { formatter } = field;
				const { args } = formatter;
				const { effectLabel, invalidLabel } = args;

				if (record[args.anchor]) {

					return (
						<div style={{display: 'inline-block'}} onClick={e => e.stopPropagation()}>
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid,no-script-url */}
							<a href='javascript:' onClick={() => {this.tips.show()}}>{stub.filter.transform(invalidLabel)}</a>
							<stub.ui.Tip onRef={ref => this.tips = ref} tips={stub.filter.transform('确定将状态设为失效？')} onOk={(e)=>this.setInvalid(e)}/>
						</div>
					)
				}
				return (
					<div style={{display: 'inline-block'}} onClick={e => e.stopPropagation()}>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid,no-script-url */}
						<a href='javascript:' onClick={() => {this.tips.show()}}>{stub.filter.transform(effectLabel)}</a>
						<stub.ui.Tip onRef={ref => this.tips = ref} tips={stub.filter.transform('确定将状态设为生效？')} onOk={(e)=>this.setEffective(e)}/>
					</div>
					)
			}
		}

		return Status
	}
}
