import { TerminalMatch,ProductMatch,SaleData,SealData } from '../components';


const addMenu = [
    {
        key: 'cleanData',
        text: '数据清洗',
        order: 20,
        icon: 'project',
        children:[
            {
                key: 'saleData',
                text: '销售数据',
                order: 10,
                component: SaleData,
                meta: {
                    permissions: 'saleData.load'
                }
            },
            {
                key: 'terminalMatch',
                text: '终端匹配',
                order: 20,
                component: TerminalMatch,
                meta: {
                    permissions: 'terminalMatch.load'
                }
            },
            {
                key: 'productMatch',
                text: '产品匹配',
                order: 30,
                component: ProductMatch,
                meta: {
                    permissions: 'productMatch.load'
                }
            },
            {
                key: 'originalTable',
                text: '标准流向表',
                order: 40,
                component: SealData,
                meta: {
                    permissions: 'saleSeal.load'
                }
            }
        ]
    }
];

export default addMenu
