import React from 'react'
import { Button, message } from 'antd'

export default {
    key: 'apply-rinseRule-action',
    order: 2500,
    build(stub) {
        return (props) => {
            const { field: { formatter: { args: { url } } } } = props;
            const onClick = (e) => {
                e && e.stopPropagation();
                this.tips.show();
            };

            const handleTips = () => {
                stub.api.get(url).then(res => {
                    if(res) {
                        message.success('清洗成功！');
                    }
                })
            };

            return(
                <div style={{display: 'inline'}}>
                    <Button type="primary" onClick={onClick}>立即清洗</Button>
                    <stub.ui.Tip onRef={ref => this.tips = ref}
                                 tips='确定要清洗数据？'
                                 onOk={handleTips} />
                </div>
            )
        }
    }
}