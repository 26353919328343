import React from 'react';
import { stub } from '@xsk/frontend-skeleton';

class AnalysisReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        return(
            <stub.ui.ListPage
                module='analysisReport'
                {...this.props}
                fixable={true}
                listMetadataKey='report-analysisReport-list'
                filterMetadataKey='report-analysisReport-filter' />
        )
    }

}

export default AnalysisReport;
