import React from 'react'
import { Button } from 'antd'

export default {
	key: 'addCustomer-action',
	order: 1200,
	build(stub, deco, decorators) {
		return (props) => {
			const { field, field: {formatter: {args}, label}, module, page } = props;
			const { type } = args;
			const sceenKey = field.formatter.args.sceenKey;

			const onClick = (e) => {
				e.stopPropagation();
				stub.ui.helper.addTabPage({
					component: stub.module[module].addComponent ||  stub.module[module].detailComponent,
					title: '添加客户',
					fromPage: page,
					sceenKey
				})
			};

			return(
				<Button type={type ? type : 'primary'} onClick={onClick}>{stub.filter.transform(label)}</Button>
			)
		}
	}
}
