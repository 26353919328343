import React from 'react';
import { stub } from '@xsk/frontend-skeleton';
import { Button } from 'antd';

class SalesBackDetail extends React.Component {

    constructor(props) {
        super(props);
        this.loaded = false;
        this.state = {
            sourceData: {},
            data: {}
        };
    }

    componentWillMount() {
        stub.api.get(stub.module.dataMaintenance.backMoneyDetail, {params: {id: this.props.page.model.id}}).then(res => {
            // props.page.others.options.sealDay
            const { page } = this.props;
            page['others'] = {
                options: {
                    sealDay: res.data.options.sealDay
                }
            };
            if (res) {
                this.dataHandle(res);
            }
        })
    }

    onClick = e => {
        e.stopPropagation();
        stub.api.get(stub.module.dataMaintenance.selNumber, {params: {id: this.props.page.model.id}}).then(res => {
            if(res) {
                let newData = this.state.data;
                newData.selNumber = res.data.result;
                this.loaded = true;
                this.setState({
                    data: newData
                });
                this.tips.show(true);
            }
        })
    };

    dataHandle = res => {
        let backList = res.data.result && res.data.result.backList ? res.data.result.backList : [];
        backList.push({
            returnMoneyRegisterDate: '总计',
            returnMoneyDate: '--',
            returnMoneyName: '--',
            returnMoneyAmount: backList.reduce((a, b) => {
                return a + (b.status ? b.returnMoneyAmount : 0);
            }, 0),
            returnMoneyNumber: backList.reduce((a, b) => {
                return a + (b.status ? b.returnMoneyNumber : 0);
            }, 0)
        });
        this.setState({
            data: res.data.result
        })
    };

    render() {
        const { page } = this.props;
        return (
            <div className='salesBackDetail-container'>
                <stub.ui.SceneContainer
                    module="dataMaintenance"
                    options={{...this.props, model: this.state.data}}
                    sceneKey='dataMaintenance-salesBack-detail'/>
                {
                    this.state.data.paymentMethod !== 'cash' ? (<div className='salesBackDetail-addBtn'>
                        <Button onClick={e => {
                            this.onClick(e)
                        }}>+添加回款</Button>
                        <stub.ui.SalesBackAdd
                            updateRecord={this.dataHandle}
                            data={this.state.data}
                            page={ page }
                            onRef={ref => this.tips = ref}
                            onOk={() => this.onOk()} />
                    </div>) : null
                }
                <div className={'salesBackDetail-container-record-container' + (this.state.data.paymentMethod === 'cash' ? ' salesBackDetail-backList-status' : '')}>
                    <div className='salesBackDetail-container-record-head'>回款登记</div>
                    <stub.ui.SceneContainer
                        module="dataMaintenance"
                        options={{...this.props, data: {list: this.state.data.backList, dataHandle: this.dataHandle, listPageData: page.listPageData}, hidePagination: true}}
                        sceneKey='dataMaintenance-salesBack-record-list'/>
                </div>
                <div className='salesBackDetail-addBtn salesBackDetail-addBtnBack'>
                    <Button onClick={() => {
                        if(page.listPageData) {
                            page.listPageData.data.filter = page.listPageData.others.filter;
                            page.listPageData.data.page = page.listPageData.others.page;
                            page.listPageData.data.sort = page.listPageData.others.sort;

                            if (this.loaded) {
                                page.listPageData.loaded = false
                            }
                        }
                        stub.ui.helper.back()
                    }}>{stub.filter.transform('common.return')}</Button>
                </div>
            </div>
        )
    }

}

export default SalesBackDetail;
