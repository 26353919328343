export default {
	env: 'LOCAL',
	defaultApiBaseUrl: '/api',
	defaultApiTimeoutInMs: 1000 * 60 * 20,
	searchPlaceholder: 'common.web.searchPlaceholder',
	iconPrimaryColor: '#FF9300',
	loginType: 'normal',
	appid: 'wxasdbdsdf',
	agentid: 'asdfsdfsdf',
	siteRootUrl: 'https://qa-qifang.softium.com.cn/',
	siteName: 'security.web.sysName',
	siteTitle: 'security.web.sysName',
	socketUrl: 'ws://localhost:8080/myaddress/websocket',
	sendApiUrl: '/receive',
	subscribeApiUrl: '/topic/getResponse',
	topHeaderSearchUrl: '/topSearch',
	enableApiBaseSettings: true,
	prodApiBaseUrl: '/prodapi',
	logoUrl: '/assets/image/logo.png',
	apiBaseSettings: {

	}
}
