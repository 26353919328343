import menu from './menu';
import moment from 'moment';
import AdjustNumber from './components/adjust';
import AdjustHistory from './components/adjustHistory';
import FlowReportDetail from './components/FlowReport/detail';

/**
 *
 * @param currentStub
 * @param page
 * @param res
 * @param type
 *
 */
const reportDecorator = (currentStub, page, res, type) => {
    const sceneKey = `report-${type}-list`;
    const pageData = currentStub.metadata.obtain(sceneKey);
    if (!page.metadata) {
        page.metadata = pageData.fields;
    }
    const data = page.data.filter.months
    const lastMetadata = (pageData.fields || []).pop();
    const newMetadata = data.map(t => {
        const date = moment(t.replace('report', '') + '01').format('YYYY-MM');
        return {
            key: t,
            label: date,
            formatter: {
                type: 'number',
                args: {
                    visible: true
                }
            }
        }
    })

    pageData.fields = [...page.metadata, ...newMetadata, lastMetadata];
    currentStub.metadata.registerMetadata([pageData]);
};

const decorators = {
    structure: {
        menu: {
            add: menu
        }
    },
    module: {
        resourceGroups: ['report.web'],
        report: {
            sceens:[
                // 异常报表提醒
                'report-exceptionReminder-list',
                'report-exceptionReminder-filter'
            ],

            // 异常报表提醒
            'report-exceptionReminder-list': {
                load: '/report/exceptionReminder/load',
                search: '/report/exceptionReminder/search'
            }
        },

        // 月度终端医院销售情况
        terminalSale: {
            sceens:[
                'report-terminalSale-list',
                'report-terminalSale-filter'
            ],
            'report-terminalSale-list': {
                load: {
                    url: '/report/terminalSale/load',
                    decorator: (currentStub, page, res) => reportDecorator(currentStub, page, res, 'terminalSale')
                },
                search: {
                    url: '/report/terminalSale/search',
                    decorator: (currentStub, page, res) => reportDecorator(currentStub, page, res, 'terminalSale')
                }
            }
        },

        // 月度终端医院数量情况
        numberHospitals: {
            sceens:[
                'report-numberHospitals-list',
                'report-numberHospitals-filter'
            ],

            // 月度终端医院数量情况
            'report-numberHospitals-list': {
                load: {
                    url: '/report/terminalCount/load',
                    decorator: (currentStub, page, res) => reportDecorator(currentStub, page, res, 'numberHospitals')
                },
                search: {
                    url: '/report/terminalCount/search',
                    decorator: (currentStub, page, res) => reportDecorator(currentStub, page, res, 'numberHospitals')
                }
            }
        },

        // 医院分析报表
        analysisReport: {
            sceens:[
                'report-analysisReport-list',
                'report-analysisReport-filter'
            ],

            // 医院分析报表
            'report-analysisReport-list': {
                load: {
                    url: '/report/analysisReport/load',
                    decorator: (currentStub, page, res) => reportDecorator(currentStub, page, res, 'analysisReport')
                },
                search: {
                    url: '/report/analysisReport/search',
                    decorator: (currentStub, page, res) => reportDecorator(currentStub, page, res, 'analysisReport')
                }
            }
        },


        // 财务报表
        flowReport: {
            scenes: [
                'report-flowReport-filter',
                'report-flowReport-list',
                'report-flowReport-detail',

                // 财务报表
                'report-flowReport-detail',
                'report-flowReport-sales-list',

                'financialFlow-edit',
                'financialFlow-detail',
                'details-edit',
                'details-detail'
            ],
            load: '/report/financialFlow/load',
            search: '/report/financialFlow/search',
            detail: '/report/financialFlow/detail',
            export: '/report/financialFlow/export',
            executeImmediate: '/report/financialFlow/excuteImmediate',
            disable: '/report/financialFlow/disable',
            adjustNumber: '/report/financialFlow/adjust',
            adjustDetail: '/report/financialFlow/adjustDetail',

            'financialFlow-detail': {
                detail: '/report/financialFlow/detail'
            },
            detailComponent: FlowReportDetail
        },
    },
    ui: {
        moreUIComponents: [ AdjustNumber, AdjustHistory ]
    }
};

export default { decorators }
