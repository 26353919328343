import { Distributor, Pharmacy, HealthCare } from "../view/institution/list"
import ExteralList from "../view/staff/Exteral"
import ProductList from "../view/product/list"

const initMenu = [];

const addMenu =[{
    parentKey: 'masterMgr',
    key: 'externalStaff',
    component: ExteralList,
    order: 20,
    text: '客户管理',
    meta: {
        permissions: 'customer.load'
    }
}]

const removedMenu = [ 'agent', 'workflow']

const updateMenu = [{
    key: 'healthCare',
    component: HealthCare,
    order: 10,
    meta: {
        permissions: 'healthcare.load'
    }
},{
    key: 'distributor',
    component: Distributor,
    text:'商业公司',
    order: 20,
    meta: {
        permissions: 'distributor.load'
    }
},{
    key: 'pharmacy',
    component: Pharmacy,
    order: 30,
    meta: {
        permissions: 'pharmacy.load'
    }
},{
    key: 'admin',
    text: '人员管理',
    order: 10,
    meta: {
        permissions: 'staff.load'
    }
}, {
    key: 'productList',
    component: ProductList,
    text: '产品管理',
    order: 40,
    meta: {
        permissions: 'product.load'
    }
}, {
    key: 'role',
    text: '角色管理',
    order: 30,
    meta: {
        permissions: 'role.load'
    }
},{
    key: 'organMgr',
    order: 50
}, {
    key: 'masterMgr',
    order: 50
}]


const moveMenu = [{
    key: 'admin',
    parentKey: 'masterMgr'
}, {
    key: 'role',
    parentKey: 'masterMgr'
}, {
    key: 'productList',
    parentKey: 'masterMgr'
}]

export  { initMenu, addMenu, removedMenu, updateMenu, moveMenu }
