import React from 'react';
import { Modal,message } from 'antd';
export default {
    key: 'terminalMatch-action',
    build(stub) {
        class TerminalMatchAction extends React.Component {
            constructor(props) {
                super(props);
                this.state = {
                    data: {},
                    model: {},
                    selectKeys: [],
                    distributorId:'',
                    terminalName:'',
                    keyword:'',
                    currentPage:1,
                    totalPage:0,
                    pageSize:5
                };
            }

            onSelectChange = (selectKeys) => {
                this.setState({
                    selectKeys
                })
            }

            onClick = e => {
                //点击匹配
                e.stopPropagation();
                const { model } = this.props;
                this.setState({
                    distributorId:model.distributorId,
                    terminalName:model.terminalName,
                })
                this.dataHandle()
                // setTimeout(()=>{
                    this.show(true);
                // },100)
            };

            dataHandle = (url,param) => {
                if(url&&param){
                    stub.api.post(url,param).then(res => {
                        if (res) {
                            this.setState({
                                data: res.data.result,
                                totalPage:res.data.result.page.total,
                            })
                        }
                    })
                }else{
                    stub.api.get(
                        stub.module.cleanTerminalMatch.masterTerminalList).then(res => {
                        if (res) {
                            this.setState({
                                data: res.data.result,
                                totalPage:res.data.result.page.total,
                            })
                        }
                    })
                }


            };

            matchDataSearch = () => {
                const {model} = this.props;
                this.setState({
                    currentPage:1,
                    keyword:model.keyword  //搜索后再点击分页发起请求时用到
                })
                let param={
                    filter:{
                        search:model.keyword,
                    },
                    page:{
                        current:1,
                        pageSize:this.state.pageSize
                    }
                }
                this.dataHandle(stub.module.cleanTerminalMatch.masterTerminalSearch,param)
            }

            show = isVisible => {
                this.setState({visible: isVisible})
            };

            onCancel=(e)=>{
                this.show(false);
                this.setState({
                    model: {},
                    selectKeys: [],
                    distributorId:'',
                    terminalName:'',
                    keyword:'',
                    currentPage:1,
                    totalPage:0
                })
            }

            onOk = (e) => {
                e.stopPropagation();
                if (this.state.selectKeys.length !== 0) {
                    let params={
                        distributorId: this.state.distributorId,
                        terminalId: this.state.selectKeys[0],
                        terminalName: this.state.terminalName,
                    }
                    stub.api.post(stub.module.cleanTerminalMatch.terminalSubmit,params).then(res => {
                        if(res) {
                            message.success('提交成功')
                            this.show(false)
                            this.props.page.data = { ...res.data.result };
                            stub.store.dispatch({type: stub.constant.actionTypes.DUMMY_ACTION})
                        }else{
                             message.error('提交失败')
                        }
                    })
                }else{
                    message.warning('请选择后再提交')
                    return false;
                }
            };

            onTableChange = (page, sorter) => {
                this.setState({
                    currentPage:page.current
                })
                let param={
                    filter:{
                        search:this.state.keyword,
                    },
                    page:{
                        pageSize:page.pageSize,
                        current:page.current
                    }
                }
                this.dataHandle(stub.module.cleanTerminalMatch.masterTerminalSearch,param)
            }

            render() {
                const { field, field: { label } } = this.props;
                const rowSelection = {
                    onChange: this.onSelectChange,
                    selectedRowKeys: this.state.selectKeys,
                    selectable: true,
                    type: 'radio'
                }
                return (
                    <div style={{display: 'inline-block'}}>
                        <span style={{color: '#FF9300', cursor: 'pointer'}}
                              onClick={e => {
                                  this.onClick(e)
                              }}>{stub.filter.transform(label)}</span>
                        <Modal
                            destroyOnClose={true}
                            visible={this.state.visible}
                            title='终端主数据'
                            okText= {stub.filter.transform('common.submit')}
                            cancelText= {stub.filter.transform('common.cancel')}
                            onOk={this.onOk}
                            onCancel={this.onCancel}
                            width="60%"
                            onRef={ref => this.tips = ref}
                            field={ field }
                        >

                            <stub.ui.SceneContainer
                                module="cleanTerminalMatch"
                                sceneKey='cleanData-masterTerminal-filter'
                                options={{
                                    ...this.props,
                                    onRef: ref => this.form = ref,
                                    onFieldChange: this.matchDataSearch,
                                }}
                            />

                            <stub.ui.SceneContainer
                                module="cleanTerminalMatch"
                                sceneKey='cleanData-masterTerminal-list'
                                options={{
                                    ...this.props,
                                    data: this.state.data,
                                    rowSelection,
                                    onTableChange: this.onTableChange
                                }}
                            />
                        </Modal>
                    </div>)
            }
        }

        return TerminalMatchAction;
    }
}
