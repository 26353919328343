import React,{ Component } from 'react'
import {stub} from '@xsk/frontend-skeleton'
import {connect} from 'react-redux'

const mapStateToProps = (state,ownProps) => {
	return{
		others: ownProps.page.others
	}
}

class Admin extends Component{
	// eslint-disable-next-line
	constructor(props) {
		super(props)
	}

	onSelect = (record, selected, selectedRows) => {
		console.log(record, selected, selectedRows);
	};

	onSelectAll = (selected, selectedRows) => {
		console.log(selected, selectedRows);
	};

	render() {
		const selectOptions = {
			onSelect: this.onSelect,
			onSelectAll: this.onSelectAll,
			selectable: false,
			selectType: 'checkbox'
		};

		return (
			<stub.ui.ListPage module="product"
							  header={stub.module.product.listHeader}
							  {...this.props}
							  selectOptions={selectOptions}
							  />
		)
	}
}

export default connect(mapStateToProps) (Admin)
