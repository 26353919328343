import React from 'react';
import {stub} from '@xsk/frontend-skeleton';
import { Table, Button, message } from 'antd';
// import FlowReportDetail from './detail';


const reportSalesRow = (row, type) => {
    return (<div className='report-flowReport-table'>
        {(row.details || []).map(t =>
            <div key={t.id}>{t[type]}</div>
        )}
    </div>)
};

class FlowReport extends React.Component {

    constructor(props) {
        super(props);
        this.defaultBusinessMonth = 0;
        this.state = {
            data: [],
            filter: {},
            adjustData: {},
            adjustHistoryData: [],
            page: {
                current: 1,
                total: 3,
                pageSize: 5
            },
            sort: {},
            tipsMessage: '',
            columns: [
                {
                    title: '业务月份',
                    dataIndex: 'businessMonth',
                    fixed: 'left'
                },
                {
                    title: '省区',
                    dataIndex: 'provinceName'
                },
                {
                    title: '品名',
                    dataIndex: 'productName'
                },
                {
                    title: '规格',
                    dataIndex: 'productSpecification'
                },
                {
                    title: '开票公司',
                    dataIndex: 'distributorName'
                },
                {
                    title: '批号',
                    dataIndex: 'batchNumber'
                },
                {
                    title: '期初结余总回款',
                    dataIndex: 'beginBackTotal'
                },
                {
                    title: '当月总回款',
                    dataIndex: 'currentBackTotal'
                },
                {
                    title: '期初结余总流向',
                    dataIndex: 'beginFlowTotal'
                },
                {
                    title: '当月总流向',
                    dataIndex: 'currentFlowTotal'
                },



                {
                    title: '销售人员',
                    dataIndex: 'salesName',
                    render(value, row) {
                        return reportSalesRow(row, 'salesName');
                    }
                },
                {
                    title: '期初结余流向明细',
                    dataIndex: 'beginFlowNum',
                    render(value, row) {
                        return reportSalesRow(row, 'beginFlowNum');
                    }
                },
                {
                    title: '当月流向明细',
                    dataIndex: 'currentFlowNum',
                    render(value, row) {
                        return reportSalesRow(row, 'currentFlowNum');
                    }
                },
                {
                    title: '当月计提流向数量',
                    dataIndex: 'currentProvisionFlowNum',
                    render(value, row) {
                        return reportSalesRow(row, 'currentProvisionFlowNum');
                    }
                },
                {
                    title: '当月超期流向数量',
                    dataIndex: 'currentOutFlowNum',
                    render(value, row) {
                        return reportSalesRow(row, 'currentOutFlowNum');
                    }
                },
                {
                    title: '期末结余流向数量',
                    dataIndex: 'endFlowNum',
                    render(value, row) {
                        return reportSalesRow(row, 'endFlowNum');
                    }
                },



                {
                    title: '当月计提总流向',
                    dataIndex: 'currentProvisionFlowTotal'
                },
                {
                    title: '当月超期总流向',
                    dataIndex: 'currentOutFlowTotal'
                },
                {
                    title: '期末结余总回款',
                    dataIndex: 'endBackTotal'
                },
                {
                    title: '期末结余总流向',
                    dataIndex: 'endFlowTotal'
                },
                {
                    title: '状态',
                    dataIndex: 'isDeleted',
                    render(value) {
                        return (
                            <div className={value ? 'flowReport-status-color' : ''}>
                                {value ? '失效' : '生效'}
                            </div>
                        )
                    }
                }
            ]
        };
    }

    componentWillMount() {
        stub.metadata.registerSceens(stub.module.flowReport.scenes);
        stub.api.get(stub.module.flowReport.load).then(res => {
            if(res) {
                const result = res.data.result;
                this.defaultBusinessMonth = res.data.setting.defaultBusinessMonth;
                stub.store.getState().options.pocket['status'] = [
                    {label: '生效', value: false},
                    {label: '失效', value: true}
                ];
                this.setState({
                    data: result.list,
                    page: result.page,
                    sort: result.sort
                })
            }
        });
        const reportFlowPermission = stub.account.hasPermission('salsflow.disable');
        const adjustPermission = stub.account.hasPermission('financialFlowAdjust.load');
        const salsflowEdit = stub.account.hasPermission('salsflow.edit');
        const _this = this;
        const columns = this.state.columns;
        columns.push({
            title: '操作',
            key: 'operation',
            fixed: 'right',
            render(value, row, index) {
                return (
                    <div className='report-flow-action-color'>
                        {
                            reportFlowPermission && row.setDisable ? (
                                <span>
                                    <a href="javascript:;" onClick={() => {
                                        _this.setState({
                                            tipsMessage: '确定要把该状态改为失效？',
                                            flag: 'status',
                                            index
                                        });
                                        _this.tips.show();
                                    }}>失效</a>
                                    <span className='report-flow-action-margin'>|</span>
                                </span>
                            ) : null
                        }
                        {
                            adjustPermission ? (
                                <span>
                                    <a href="javascript:;" onClick={() => {
                                        _this.adjustNumber.show(true);
                                        _this.setState({
                                            adjustData: row
                                        })
                                    }}>调整</a>
                                    <span className='report-flow-action-margin'>|</span>
                                </span>
                            ) : null
                        }
                        <a href="javascript:;" onClick={() => {
                            _this.getAdjustHistory(row.id);
                        }}>历史</a>
                        {
                            salsflowEdit ? (
                                <span>
                                    <span className='report-flow-action-margin'>|</span>
                                    <a href="javascript:;" onClick={() => {
                                        _this.handleAddClick('detail', row.id);
                                    }}>详情</a>
                                </span>
                            ) : null
                        }

                    </div>
                );
            }
        });
        this.setState({
            columns
        })
    }

    getAdjustHistory = id => {
        if(!id) return;
        stub.api.get(stub.module.flowReport.adjustDetail, {params: {id: id}}).then(res => {
            if(res) {
                this.setState({
                    adjustHistoryData: res.data.result
                });
                this.adjustHistory.show(true);
            }
        })
    };

    onFilterChange = (page) => {
        const params = {
            filter: this.state.filter,
            page: page ? page : this.state.page,
            sort: this.state.sort
        };
        stub.api.post(stub.module.flowReport.search, params).then(res => {
            if(res) {
                this.setState({
                    data: res.data.result.list,
                    page: res.data.result.page
                })
            }
        });
    };

    handleClick = () => {
        stub.api.post(stub.module.flowReport.export, {
            filter: this.state.filter,
            sort: this.state.sort
        }, {
            responseType: 'blob',
            headers: {
                supressTruncature: true
            }
        }).then(res => {
            stub.file.export(res);
        })
    };

    // handleExecuteImmediate = () => {
    //     this.setState({
    //         tipsMessage: '确定要立即执行？',
    //         flag: 'execute'
    //     });
    //     this.tips.show();
    // };

    handleTips = () => {
        if(this.state.flag === 'execute') {
            stub.api.post(stub.module.flowReport.executeImmediate).then(res => {
                if(res) {
                    message.info('执行成功');
                }
            })
        }else if(this.state.flag === 'status') {
            const data = this.state.data;
            const currentData = data[this.state.index];
            const params = {
                filter: {...this.state.filter, financialFlowId: currentData.id},
                page: this.state.page,
                sort: this.state.sort
            };
            stub.api.post(stub.module.flowReport.disable, params).then(res => {
                if(res) {
                    message.info('修改成功');
                    currentData.isDeleted = 1;
                    currentData.setDisable = false;
                    this.setState({
                        data
                    })
                }
            })
        }
    };

    handleAddClick = (key, id) => {
        let params = {
            component: stub.module.flowReport.detailComponent,
            title: key === 'edit' ? `添加财务报表` : '查看财务报表',
            fromPage: {
                data: {
                    filter: this.state.filter,
                    page: this.state.page,
                    sort: this.state.sort
                }
            },
            data: {
                businessMonth: this.defaultBusinessMonth
            },
            sceenKey: `financialFlow-${key}`
        };
        if(key === 'detail') {
            params.params = {
                id: id
            }
        }
        stub.ui.helper.addTabPage(params)
    };

    componentDidMount() {
        this.tableTheadFixed();
    }

    tableTheadFixed = () => {
        setTimeout(() => {
            const thead = this.ref.querySelectorAll('.ant-table-thead');
            [...thead].map(item => {
                const node = item.children[0].children;
                return [...node].map(t => {
                    const div = document.createElement('div');
                    div.innerHTML = t.innerHTML;
                    return t.appendChild(div);
                })
            });

            const uiTabs = document.querySelector('.ui-tabs');
            const antTableScroll = uiTabs.querySelector('.report-flowReport-table-container');
            uiTabs.addEventListener('scroll', e => {
                if(e.target.scrollTop >= antTableScroll.offsetTop) {
                    [...thead].map(item => {
                        const node = item.children[0].children;
                        return [...node].map(t => {
                            return t.children[0].style.top = e.target.scrollTop - antTableScroll.offsetTop - 10 +'px';
                        })
                    });
                }else {
                    [...thead].map(item => {
                        const node = item.children[0].children;
                        return [...node].map(t => {
                            return t.children[0].style.top = 0 +'px';
                        })
                    });
                }
            })
        })
    };

    adjustNumberData = (data) => {
        const adjustData = this.state.adjustData;
        adjustData.endBackTotal = data;
        this.setState({
            adjustData
        })
    };

    render() {
        const salsflowEdit = stub.account.hasPermission('salsflow.edit');
        return (
            <div className='report-flowReport-container'>
                <stub.ui.Tip onRef={ref => this.tips = ref}
                             tips={this.state.tipsMessage}
                             onOk={this.handleTips} />
                <stub.ui.AdjustNumber model={this.state.adjustData} onRef={ref => this.adjustNumber = ref} adjustNumberData={this.adjustNumberData} />
                <stub.ui.AdjustHistory adjustHistoryData={this.state.adjustHistoryData} onRef={ref => this.adjustHistory = ref} />
                <div className='report-filter-flex'>
                    <div className='report-filter-flex-right'>
                        <stub.ui.SceneContainer
                            module='report'
                            sceneKey='report-flowReport-filter'
                            options={{
                                ...this.props,
                                onRef: ref => this.filter = ref,
                                onFieldChange: ()=> this.onFilterChange(),
                                model: this.state.filter
                            }} />
                    </div>
                    <div className='report-flowReport-export-container'>
                        <Button type='type' className='ant-btn ant-btn-primary' onClick={() => {this.handleClick()}}>导出</Button>
                        {
                            salsflowEdit ? (
                                <Button type='type' className='ant-btn ant-btn-primary' onClick={() => {this.handleAddClick('edit')}}>添加</Button>
                            ) : null
                        }

                    </div>
                </div>
                <div className='report-flowReport-table-container ui-table-container' ref={ref => this.ref = ref}>
                    <Table
                        scroll={{x: 'max-content'}}
                        columns={this.state.columns}
                        dataSource={this.state.data}
                        bordered
                        rowKey={t => t.id}
                        expandIcon={() => null}
                        expandedRowKeys={this.state.data.map(t => t.id)}
                        pagination={{
                            pageSize: this.state.page.pageSize,
                            total: this.state.page.total
                        }}
                        onChange={this.onFilterChange}
                    />
                </div>
            </div>
        )
    }

}

export default FlowReport;
