import React from 'react';
import { stub } from '@xsk/frontend-skeleton';

class FlowReportDetail extends React.Component {

    constructor(props) {
        super(props);
        this.options = {
            layout: "inline",
            formItemLayout: {
                labelCol: {span: 4},
                wrapperCol: {span: 18},
            },
            formItemCol: 12
        }
    }

    render() {
        return (<stub.ui.DetailPage {...this.props} sceenKey={this.props.page.sceenKey}  module="flowReport" />)
    }

}

export default FlowReportDetail;
