import { ExceptionReminder, AnalysisReport, NumberHospitals, HospitalSales, FlowReport } from '../components';

const menu = [
    {
        key: 'report',
        text: '数据报表',
        order: 40,
        icon: 'area-chart',
        children: [
            {
                key: 'exceptionReminder',
                text: '异常销量提醒',
                order: 10,
                component: ExceptionReminder,
                meta: {
                    permissions: 'businessLevelExceptionStatistics.load'
                }
            },
            {
                key: 'AnalysisReport',
                text: '终端医院分析表',
                order: 20,
                component: AnalysisReport,
                meta: {
                    permissions: 'terminalStatistics.load'
                }
            },
            {
                key: 'NumberHospitals',
                text: '月度终端医院数量',
                order: 30,
                component: NumberHospitals,
                meta: {
                    permissions: 'monthlyTerminalCount.load'
                }
            },
            {
                key: 'HospitalSales',
                text: '月度终端医院销量',
                order: 40,
                component: HospitalSales,
                meta: {
                    permissions: 'monthlyTerminalSales.load'
                }
            },
            {
                key: 'FlowReport',
                text: '财务流向数量表',
                order: 50,
                component: FlowReport,
                meta: {
                    permissions: 'salsflow.load'
                }
            }
        ]
    }
];

export default menu;
