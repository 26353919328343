import React from 'react';
import { stub } from '@xsk/frontend-skeleton';
import Chart from '../Chart';

class HospitalSales extends React.Component {
    render() {
        return(
            <stub.ui.ListPage
                module='terminalSale'
                {...this.props}
                fixable={true}
                middleContent={Chart}
                listMetadataKey='report-terminalSale-list'
                filterMetadataKey='report-terminalSale-filter' />
        )
    }

}

export default HospitalSales;
