import addMenu from './menu';
import metadata from "./metadata";
import { SalesBackDetail } from './components';
import SalesBackAdd from './components/SalesBackAdd';
import validator from './validator';

const decorators = {
    structure: {
        menu: {
            add: addMenu
        }
    },
    module: {
        resourceGroups: ['dataMaintenance.web'],
        dataMaintenance: {
            sceens: [
                // 回款数据
                'dataMaintenance-salesBack-filter',
                'dataMaintenance-salesBack-list',

                // 回款详情
                'dataMaintenance-salesBack-detail',
                'dataMaintenance-salesBack-record-list',

                // 回款登记/修改
                'dataMaintenance-salesBack-edit',

                // 账期
                'dataMaintenance-paymentDay-edit'
            ],

            // 回款数据
            'dataMaintenance-salesBack-list': {
                load: '/dataMaintenance/salesBack/load',
                search: '/dataMaintenance/salesBack/search',
                salesBackComponentDetail: SalesBackDetail
            },

            'dataMaintenance-salesBack-detail': {
                detail: '/dataMaintenance/backMoney/detail'
            },
            backMoneyDetail: '/dataMaintenance/salesBack/detail',
            backMoneyAdd: '/dataMaintenance/backMoney/add',
            backMoneyStatus: '/dataMaintenance/salesBack/status',
            paymentDayUpdate: '/dataMaintenance/paymentDay/update',
            selNumber: '/dataMaintenance/salesBack/selNumber'
        },
        terminalMapping:{
            sceens: [
                'terminal-mapping-list',
                'terminal-mapping-filter'
            ],
            // 对应终端关系
            'terminal-mapping-list': {
                load: '/terminalMapping/load',
                search: '/terminalMapping/search',
            },
            terminalMappingStatus: '/terminalMapping/changeStatus',
        },
        productMapping:{
            sceens: [
                'product-mapping-list',
                'product-mapping-filter'
            ],
            // 对应产品关系
            'product-mapping-list': {
                load: '/productMapping/load',
                search: '/productMapping/search',
            },
            productMappingStatus: '/productMapping/changeStatus',
        }
    },
    ui: {
        moreUIComponents: [ SalesBackAdd ],
        metadata: {
            moreMetadataComponents: metadata
        }
    },
    metadata: {
        validators: validator
    },
};

export default { decorators }
