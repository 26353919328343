import React from 'react';
import { stub } from '@xsk/frontend-skeleton';

export default class SalesVolumeSummery extends React.Component {

    rowClassName = (record, index) => {
        if (record.isPaymentDay) {
            return "sales-back-is-payment-overdue"
        }else if(record.isOverdue) {
            return "sales-back-is-payment-day"
        }
    };

    render() {
        return(
            <stub.ui.ListPage
                module='dataMaintenance'
                data={{sort: {}}}
                {...this.props}
                fixable={true}
                listMetadataKey='dataMaintenance-salesBack-list'
                filterMetadataKey='dataMaintenance-salesBack-filter'
                rowClassName={this.rowClassName}
            />
        )
    }

}
