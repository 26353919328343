import menu from './menu';
import { SendGoodsDetail, SalesDataDetail } from './components';

const decorators = {
    structure: {
        menu: {
            add: menu
        }
    },
    module: {
        resourceGroups: ['collection.web'],
        collection: {
            sceens: [
                // 发货源数据
                'collection-sendGoods-list',
                'collection-sendGoods-filter',
                'collection-sendGoods-detail',

                // 销售源数据
                'collection-salesData-list',
                'collection-salesData-filter',
                'collection-salesData-detail'
            ],

            // 发货源数据
            'collection-sendGoods-list': {
                load: '/collection/sendGoods/load',
                search: '/collection/sendGoods/search'
            },
            'collection-sendGoods-detail': {
                detail: '/collection/sendGoods/detail',
                detailComponent: SendGoodsDetail
            },

            // 销售源数据
            'collection-salesData-list': {
                load: '/collection/salesData/load',
                search: '/collection/salesData/search'
            },
            'collection-salesData-detail': {
                detail: '/collection/salesData/detail',
                detailComponent: SalesDataDetail
            }
        }
    }
};

export default { decorators };
