import React, { Component } from 'react'
import { stub } from '@xsk/frontend-skeleton'
import metadata from "./metadata";
import merge from "deepmerge";

class AttachedStation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            headerData: merge({ jurisdictionType: '销售辖区'}, props.page.params)
        };
        delete props.page.params.code;
        delete props.page.params.roleName;
        delete props.page.params.staffName;
        delete props.page.params.name;
    }

    componentWillMount() {
        stub.metadata.registerMetadata(metadata)
    }

    header = () => (
        <stub.ui.SceneContainer
            sceneKey="jurisdiction-customer-terminal-detail"
            options={{model: this.state.headerData || {}}}
        />
    );

    render() {

        const selectOptions = {
            selectable: true,
            selectType: 'checkbox'
        };

        return(
            <div className="ui-content">
                <stub.ui.ListPage
                    {...this.props}
                    hidePagination={true}
                    listMetadataKey="jurisdiction-customer-station-list"
                    filterMetadataKey="jurisdiction-customer-station-filter"
                    header={this.header}
                    module="jurisdiction-customer"
                    selectOptions={selectOptions}
                />
            </div>
        )
    }
}

export default AttachedStation
