import React from 'react';
import { stub } from '@xsk/frontend-skeleton';

export default class SealData extends React.Component {
    render() {
        return(
            <stub.ui.ListPage
                module='cleanSealData'
                {...this.props}
                fixable={true}
                listMetadataKey='cleanData-sealData-list'
                filterMetadataKey='cleanData-sealData-filter'
            />
        )
    }

}
