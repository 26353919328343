import { SendGoodsList, SalesData } from '../components';
const menu = [
    {
        key: 'collectionMag',
        text: '数据采集',
        order: 10,
        icon: 'fund',
        children: [
            {
                key: 'sendGoods',
                text: '发货源数据',
                order: 10,
                component: SendGoodsList,
                meta: {
                    permissions: 'sendGoods.load'
                }
            },
            {
                key: 'saleOriginal',
                text: '销售源数据',
                order: 20,
                component: SalesData,
                meta: {
                    permissions: 'saleOriginal.load'
                }
            }
        ]
    }
];

export default menu;
