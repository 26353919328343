import React, { Component } from 'react'
import { Divider, Button, Steps } from 'antd'
import { stub } from '@xsk/frontend-skeleton'

class StepThird extends Component {

    handleReturn = (e) => {
        e&&e.stopPropagation();
        stub.api.post('/institution/search', {
            filter: {
                category: this.props.page.fromPage.data.category
            },
            page: {
                pageSize: 20,
                current: 1
            }
        }).then(res => {
            stub.ui.helper.back(res.data.result)
        })
    };
    render() {
        const { page } = this.props;
        const steps = [
            "institution.web.add.firstStep",
            "institution.web.add.secondStep",
            "institution.web.add.thirdStep"
        ];
        return(
            <div className="ui-content">
                <Steps current={2} className="institution-steps">
                    {steps.map(item => (
                        <Steps.Step key={item} title={stub.filter.transform(item)} />
                    ))}
                </Steps>
                <stub.ui.ResultPage data={page.data}/>
                <div className="institution-add-footer">
                    <Divider />
                    <Button onClick={this.handleReturn}>{stub.filter.transform("common.return")}</Button>
                </div>
            </div>
        )
    }
}

export default StepThird