import React from 'react';

export default {
    key: 'salesBackDelete-action',
    build(stub) {
        class SalesBackDeleteAction extends React.Component {
            constructor(props) {
                super(props);
                this.state = {};
            }

            onOk = () => {
                const { field: { formatter: { args } }, page, model } = this.props;
                stub.api.get(args.url, {params: {id: model.id}}).then(res => {
                    page.data = {...res.data.result};
                    stub.store.dispatch({type: stub.constant.actionTypes.DUMMY_ACTION})
                })
            };

            render() {
                const { field: {label } } = this.props;
                return (
                    <div style={{display: 'inline-block'}}>
                        <a href="javascript:" onClick={() => {this.tips.show()}}>{stub.filter.transform(label)}</a>
                        <stub.ui.Tip onRef={ref => this.tips = ref}
                                     tips='确定删除本地回款信息？'
                                     onOk={() => this.onOk()}/>
                    </div>)
            }
        }

        return SalesBackDeleteAction;
    }
}
