import React from 'react';
import { stub } from '@xsk/frontend-skeleton';

export default class SaleData extends React.Component {
    render() {
        return(
            <stub.ui.ListPage
                module='cleanSaleData'
                {...this.props}
                fixable={true}
                listMetadataKey='cleanData-saleData-list'
                filterMetadataKey='cleanData-saleData-filter'
            />
        )
    }

}
