import React from 'react';
import { stub } from '@xsk/frontend-skeleton';

class SendGoodsList extends React.Component {
    render() {
        const selectOptions = {
            selectable: true,
            selectType: 'checkbox'
        };
        return (
            <stub.ui.ListPage
                module='collection'
                {...this.props}
                fixable={true}
                selectOptions={selectOptions}
                listMetadataKey='collection-sendGoods-list'
                filterMetadataKey='collection-sendGoods-filter' />
        )
    }

}

export default SendGoodsList;
