import React from 'react';

export default {
    key: 'saleDataDetail-action',
    build(stub) {
        class SaleDataDetailAction extends React.Component {
            constructor(props) {
                super(props);
                this.state = {};
            }

            onClick = e => {
                e.stopPropagation();
                const { field, sceenKey, module, model, page } = this.props;
                const { formatter: { args: { listKey, filterKey } } } = field;
                stub.ui.helper.addTabPage({
                    component: stub.module[module][sceenKey].saleDataComponentDetail,
                    listKey: listKey,
                    filterKey: filterKey,
                    title: '销售数据详情',
                    model: model,
                    listPageData: page
                })
            };

            render() {
                const { field: { label } } = this.props;
                return(<a href="javascript:" onClick={this.onClick}>{stub.filter.transform(label)}</a>)
            }
        }

        return SaleDataDetailAction;
    }
}
