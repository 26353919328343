import SalesBackDetailAction from './salesBackDetail';
import SalesBackDeleteAction from './salesBackDelete';
import SalesBackRegisterAction from './salesBackAdd';
import SalesBackUpdateAction from './salesBackUpdate';
import RegisterStatusAction from './registerStatus';
import PaymentDaysActionAction from './paymentDays';
import terminalSetToValid from './terminalSetToValid'
import terminalSetToInvalid from './terminalSetToInvalid'
import productSetToValid from './productSetToValid'
import productSetToInvalid from './productSetToInvalid'
import salesBatchDelete from './salesBatchDelete'

export default [ SalesBackDetailAction, SalesBackDeleteAction, SalesBackRegisterAction, SalesBackUpdateAction, terminalSetToValid,RegisterStatusAction,
    PaymentDaysActionAction,terminalSetToInvalid,productSetToValid,productSetToInvalid, salesBatchDelete ];
