import React, { Component } from 'react'
import { Typography } from 'antd'

export default {
  order: 1010,
  key: 'tooltip-namedesc',
  build(stub, deco, decorators) {

    class NameDesc extends Component{
      render() {
        const { value, model } = this.props
        return(
          <div>
            <Typography.Title>{model.id}</Typography.Title>
            <Typography.Title>{value}</Typography.Title>
            <Typography.Title>{stub.filter.formatDate(model.date)}</Typography.Title>
          </div>
        )
      }
    }
    return NameDesc
  }
}
