import React from 'react';
import { Chart, Geom, Axis, Tooltip, Legend, Label } from "bizcharts";
import moment from 'moment';

class ChartComponent extends React.Component {
    render() {
        let reportData = [];
        let newReportData = [];
        const cols = {
            month: {
                range: [0, 1]
            }
        };
        const { page, page: { data } } = this.props;
        /**
         * {
                month: "Jan",
                city: "Tokyo",
                temperature: 7
            }
         */
        if(data.imgList) {
            reportData = [];
            const list = data.imgList[0];
            for(let key in list) {
                if(/^report\d{6}/.test(key)) {
                    const date = moment(key.replace('report', '') + '01').format('YYYY-MM');
                    reportData.push({
                        month: date,
                        product: '',
                        key: key
                    })
                }
            }
            for(let obj of data.imgList) {
                const report = JSON.parse(JSON.stringify(reportData));
                for(let item in report) {
                    report[item]['product'] = obj.productName + obj.productSpecification;
                    report[item]['temperature'] = obj[report[item].key];
                    newReportData.push(report[item]);
                }
            }
        }
        (newReportData.length === 0 ? reportData : newReportData).sort((a, b) => {
            return a.month.replace('-', '') - b.month.replace('-', '')
        });
        return (
            <div>
                <div className='report-title'>{page.title}</div>
                <Chart height={400} data={newReportData.length === 0 ? reportData : newReportData} scale={cols} forceFit>
                    <Legend />
                    <Axis name='month' />
                    <Axis
                        name='temperature'
                        label={{
                            formatter: val => `${val}`
                        }}
                    />
                    <Tooltip
                        crosshairs={{type: 'y'}}
                    />
                    <Geom
                        type='line'
                        position='month*temperature'
                        size={2}
                        color={'product'}
                        shape={'smooth'}
                    />
                    <Geom
                        type='point'
                        position='month*temperature'
                        size={4}
                        shape={'circle'}
                        color={'product'}
                        style={{
                            stroke: '#fff',
                            lineWidth: 1
                        }}>
                        <Label content='temperature' offset='10' />
                    </Geom>
                </Chart>
            </div>
        );
    }

}

export default ChartComponent;
