import React, { Component } from 'react'
import { stub } from '@xsk/frontend-skeleton'
import metadata from "./metadata";
import merge from 'deepmerge'

class Detail extends Component{
    constructor(props){
        super(props);
        const params = merge({}, props.page.params)
        this.state = {
            data: null,
            headerData: {
                ...params,
                jurisdictionType: '销售辖区'
            },
        }
        props.page.params = {
            jurisdictionId: params.jurisdictionId,
            jurisdictionStaffId: params.jurisdictionStaffId,
            customerId: params.customerId
        }
    }

    componentWillMount() {
        stub.metadata.registerMetadata(metadata)
    }

    middleContent = () => {
        return(
            <stub.ui.SceneContainer
                sceneKey="jurisdiction-customer-terminal-detail"
                options={{model: this.state.headerData}}
            />
        )
    };

    render() {

        const selectOptions = {
            selectable: true,
            selectType: 'checkbox'
        };

        return(
            <React.Fragment>
                <stub.ui.ListPage
                    {...this.props}
                    module="jurisdiction-terminal"
                    listMetadataKey="jurisdiction-terminal-list"
                    filterMetadataKey="jurisdiction-terminal-filter"
                    selectOptions={selectOptions}
                    middleContent={this.middleContent}
                />

            </React.Fragment>
        )
    }
}


export default Detail
