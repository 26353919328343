import addMenu from './menu';
import metadata from "./metadata";
import { SaleDataDetail, SealDataDetailComponent } from './components';

const decorators = {
    structure: {
        menu: {
            add: addMenu
        }
    },
    module: {
        cleanTerminalMatch: {
            sceens: [
                //终端匹配
                'cleanData-terminalMatch-filter',
                'cleanData-terminalMatch-list',

                //终端主数据
                'cleanData-masterTerminal-list',
                'cleanData-masterTerminal-filter',


            ],
            // 终端匹配
            'cleanData-terminalMatch-list': {
                load: '/terminalRinse/load',
                search: '/terminalRinse/search',
            },
            masterTerminalList: '/terminalRinse/masterTerminalLoad',
            masterTerminalSearch:'/terminalRinse/masterTerminalSearch',
            terminalSubmit:'/terminalRinse/match',
        },
        cleanProductMatch:{
            sceens:[
                //产品匹配
                'cleanData-productMatch-filter',
                'cleanData-productMatch-list',

                //产品主数据
                'cleanData-masterProduct-list',
                'cleanData-masterProduct-filter',
            ],
            // 产品匹配
            'cleanData-productMatch-list': {
                load: '/productRinse/load',
                search: '/productRinse/search',
            },
            masterProductList: '/productRinse/masterProductLoad',
            masterProductSearch:'/productRinse/masterProductSearch',
            productSubmit:'/productRinse/match',
        },
        cleanSaleData:{
            sceens:[
                //销售数据
                'cleanData-saleData-list',
                'cleanData-saleData-filter',
                'cleanData-saleData-detail'
            ],
            // 销售数据
            'cleanData-saleData-list': {
                load: '/saleData/load',
                search: '/saleData/search',
                saleDataComponentDetail:SaleDataDetail
            },
            saleDataDetailUrl:'/saleData/detail'
        },
        cleanSealData:{
            sceens:[
                //标准流向表
                'cleanData-sealData-filter',
                'cleanData-sealData-list',
                'cleanData-sealData-detail'
            ],
            'cleanData-sealData-list': {
                load: '/sealData/load',
                search: '/sealData/search'
            },
            'cleanData-sealData-detail': {
                detailComponent: SealDataDetailComponent
            },
            sealDataDetailUrl:'/sealData/detail'
        }
    },
    ui: {
        metadata: {
            moreMetadataComponents: metadata
        }
    }
};

export default { decorators }
