import React, { Component } from 'react'
import { stub } from '@xsk/frontend-skeleton'

class JurisdictionCustomer extends Component {

    render() {
        const selectOptions = {
            selectable: true,
            selectType: 'checkbox'
        };

        return(
            <stub.ui.ListPage
                {...this.props}
                module="jurisdiction-customer"
                listMetadataKey="jurisdiction-customer-list"
                filterMetadataKey="jurisdiction-customer-filter"
                selectOptions={selectOptions}
            />
        )
    }
}

export default JurisdictionCustomer
