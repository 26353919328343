import React,{ Component } from 'react'
import {stub} from '@xsk/frontend-skeleton'

class TerminalRelative extends Component{
    render() {
        const selectOptions = {
            selectable: false,
        }
        return (
            <stub.ui.ListPage
                listMetadataKey="terminal-mapping-list"
                filterMetadataKey="terminal-mapping-filter"
                module="terminalMapping"
                fixable={false}
                {...this.props}
                selectOptions={selectOptions} />
        )
    }
}

export default TerminalRelative