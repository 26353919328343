export default [
    {
        key: 'jurisdiction-customer-terminal-detail',
        kind: 'detail',
        fields: [
            {
                key: 'title',
                formatter: {
                    type: 'title',
                    args: {
                        title: 'name',
                        subtitle: 'code',
                        visible: true,
                        col: 24,
                        group: 'basic-no-border'
                    }
                }
            },
            {
                key: 'name',
                formatter: {
                    type: 'text',
                    args: {
                        visible: false
                    }
                }
            },
            {
                key: 'code',
                formatter: {
                    type: 'text',
                    args: {
                        visible: false
                    }
                }
            },
            {
                key: 'staffName',
                label: '辖区负责人',
                formatter: {
                    type: 'text',
                    args: {
                        visible: true,
                        group: 'basic-no-border'
                    }
                }
            },
            {
                key: 'jurisdictionType',
                label: '辖区类型',
                formatter: {
                    type: 'text',
                    args: {
                        visible: true,
                        group: 'basic-no-border'
                    }
                }
            },
            {
                key: 'customerName',
                label: '客户',
                formatter: {
                    type: 'text',
                    args: {
                        visible: true,
                        group: 'basic-no-border'
                    }
                }
            }
        ]
    }
]