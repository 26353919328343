import React, { Component } from 'react'
import { stub } from '@xsk/frontend-skeleton'

class JurisdictionStaff extends Component {

    render() {
        const selectOptions = {
            selectable: true,
            selectType: 'checkbox'
        };

        return(
            <stub.ui.ListPage
                {...this.props}
                module="jurisdiction-staff"
                listMetadataKey="jurisdiction-staff-list"
                filterMetadataKey="jurisdiction-staff-filter"
                selectOptions={selectOptions}
            />
        )
    }
}

export default JurisdictionStaff
